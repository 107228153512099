import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { HxOrderService } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hc-otp',
  templateUrl: './hc-otp.component.html',
  styleUrls: ['./hc-otp.component.css']
})
export class HcOtpComponent {
  @Input() orderId!: number;
  @Input() clientId!: number;
  @Output() otpConfirm = new EventEmitter<void>();

  isConfirmOtp = false;
  isCodeSend = false;
  code?: string;
  resendTimeout?: number;
  private interval?: NodeJS.Timeout;

  constructor(
    private orderService: HxOrderService,
    private toastr: ToastrService,
    private errorService: ErrorHandlerService,
    private tr: TranslocoService
  ) { }

  sendCode() {
    if (this.resendTimeout && this.resendTimeout > 0) {
      this.toastr.error(this.tr.translate('hc-otp.sendCode', {timeOut: this.resendTimeout}));
      return;
    }

    this.startTimeout();
    this.isCodeSend = true;

    this.orderService.sendOtpCode({clientId: this.clientId, orderId: this.orderId}).subscribe(() => { }, err => {
      this.isCodeSend = false;
      clearInterval(this.interval);
      this.resendTimeout = 0;
      this.errorService.handle(err);
    });
  }

  checkCode() {
    if (!this.code) {
      this.toastr.error(this.tr.translate('hc-otp.enterCode'));
      return;
    }

    this.orderService.confirmOtpCode({orderId: this.orderId, code: this.code, clientId: this.clientId}).subscribe(() => {
      this.isConfirmOtp = true;
      this.code = undefined;
      this.otpConfirm.emit();
    }, () => {
      this.isConfirmOtp = false;
    });
  }

  private startTimeout() {
    this.resendTimeout = 2 * 60;

    this.interval = setInterval(() => {
      if (this.resendTimeout && this.resendTimeout > 0) {
        this.resendTimeout--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

}
