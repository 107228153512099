<div class="m-list-timeline">
  <div class="m-list-timeline__items" *ngIf="events.length">
    <div class="m-list-timeline__item m--font-bolder">
      <span class="m-list-timeline__text">
        {{ 'invoice.events.date' | transloco }}
      </span>
      <span class="m-list-timeline__text">
        {{ 'invoice.events.author' | transloco }}
      </span>
      <span class="m-list-timeline__text">
        {{ 'invoice.events.note' | transloco }}
      </span>
      <span class="m-list-timeline__time">
        {{ 'invoice.events.status' | transloco }}
      </span>
    </div>
    <div class="m-list-timeline__item" *ngFor="let event of events">
      <span class="m-list-timeline__text">
        {{ event.date | date: 'dd.MM HH:mm' }}
      </span>
      <span class="m-list-timeline__text">
        <span [title]="event.actorFullName + ' - ' + event.actor">{{ event.actorFullName | fio }}</span>
      </span>
      <span class="m-list-timeline__text">
        {{event.info }}
      </span>
      <span class="m-list-timeline__time">
        <span class="m-badge m-badge--{{eventList.get(event.type) ?? 'default' }} m-badge--wide m-badge--rounded">
          {{ 'invoice.type.' + event.type | transloco }}
        </span>
      </span>
    </div>
  </div>
</div>
