import { Pipe, PipeTransform } from '@angular/core';
import { OrderResponse, PaymentHolder, toPaymentArray } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'paymentInfo'
})
export class PaymentInfoPipe implements PipeTransform {

  constructor(private tr: TranslocoService) {
  }

  transform(value?: PaymentHolder, childOrders?: OrderResponse[]): string {
    if (!value) {
      return '';
    }
    return toPaymentArray(value).map(p => this.tr.translate(p.type) + ' = ' + p.value).join(', ');
  }
}
