/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type KindType = 'DEFAULT' | 'OPTIONAL' | 'REGULAR';

export const KindType = {
    DEFAULT: 'DEFAULT' as KindType,
    OPTIONAL: 'OPTIONAL' as KindType,
    REGULAR: 'REGULAR' as KindType
};

