<footer class="m-grid__item		m-footer" appunwraptag="">
  <div class="m-container m-container--fluid m-container--full-height m-page__container">
    <div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
      <div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
        <span class="m-footer__copyright">
          &copy; {{ 'footer.copyRightLeft' | transloco }} {{ currentYear }} {{ 'footer.copyRightRight' | transloco }}.
        </span>
        <span style="font-size: 0.9rem;">
          v{{ appVersion }}
        </span>
      </div>
    </div>
  </div>
</footer>
