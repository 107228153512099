import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Action, Notification, NotificationType, NotifierEventBusAddress, NotifierService } from 'hx-services';
import { takeUntil } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { CashboxService } from '@cashbox-app/service/cashbox.service';

@Injectable({
  providedIn: 'root'
})
export class HcNotificationService {
  private readonly address = NotifierEventBusAddress.NOTIFICATIONS;

  private eventListSubject = new ReplaySubject<any>(1);
  private eventCountSubject = new ReplaySubject<any>(1);
  private eventAlertSubject = new ReplaySubject<any>(1);

  readonly eventListObs = this.eventListSubject.asObservable();
  readonly eventCountObs = this.eventCountSubject.asObservable();
  readonly eventAlertObs = this.eventAlertSubject.asObservable();
  private username?: string;

  private $destroyed = new Subject<void>();
  private notificationHandler: (err: any, message: { body: any, headers?: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      console.log('[notification] message', message);
      const action: Action | undefined = message.headers?.action;
      if (action === 'curbsidePickupDeactivated') {
        this.cashboxService.handleCurbsidePickupEvent(false);
      } else if (action === 'curbsidePickupCreated') {
        this.cashboxService.handleCurbsidePickupEvent(true);
        const notificationId = message.body.id;
        this.markAsSeen([notificationId]);
        console.log('[notification] mark as seen for curbside pickup notification', notificationId);
      } else if (action === 'refreshCashboxState') {
        this.cashboxService.saveCashboxState({online: true});
      }
    }
  };

  constructor(
    private notifierService: NotifierService,
    private keycloakService: KeycloakService,
    private cashboxService: CashboxService,
  ) {
  }

  start(phone: string, storeId: number) {
    const username = NotifierEventBusAddress.user(phone);
    this.username = username;
    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(async eb => {
      if (eb) {
        await this.cashboxService.saveCashboxState({online: true});
        const token = await this.keycloakService.getToken();
        this.notifierService.addHandler({
          name: username,
          handler: this.notificationHandler,
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        this.notifierService.addHandler({
          name: NotifierEventBusAddress.store(storeId),
          handler: this.notificationHandler,
          headers: {
            authorization: `Bearer ${token}`
          }
        });
        const result: { headers: any, body: { count: number, list: Notification[] } } = await this.notifierService.send(NotifierEventBusAddress.NOTIFICATIONS, {}, {
          username: this.username,
          action: 'getUnseenMessages',
        });
        result.body.list.forEach(notification => this.notificationHandler(undefined, {body: notification}));
      } else {
        await this.cashboxService.saveCashboxState({online: false});
      }
    });
  }

  stop() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  getAllMessages(): void {
    this.getMessageCount();

    this.notifierService.send(this.address, {}, {username: this.username, action: 'getMessages'}).then(res => {
      if (res.body && res.body.list) {
        this.filterAlert(res.body.list);
      }
      this.eventListSubject.next(res.body);
    }, err => this.eventListSubject.error(err));
  }

  markAsRead(messageIds: number[]) {
    return this.notifierService.send(this.address, {ids: messageIds}, {username: this.username, action: 'markAsRead'});
  }

  getMessageCount() {
    this.notifierService.send(this.address, {}, {username: this.username, action: 'getMessageCount'}).then(result => {
      this.eventCountSubject.next(result.body);
    }, err => this.eventCountSubject.error(err));
  }

  markAsSeen(messageIds: number[]) {
    return this.notifierService.send(this.address, {ids: messageIds}, {username: this.username, action: 'markAsSeen'});
  }

  filterAlert(arr: Notification[]) {
    const list = arr.filter(el => el.type === NotificationType.ALERT && !el.readDate);

    if (list.length) {
      this.eventAlertSubject.next(list);
    }
  }
}

