import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { Subscription } from 'rxjs';
import {
  ComponentType,
  DeliveryOrderWithProducts,
  DeliveryOrderWithProductsBucketModel,
  Entity,
  HxAuthService,
  isoDate,
  OrderAction,
  ReceiptModel,
  UserBasicModel
} from 'hx-services';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { HxOrderReceiptComponent } from 'hx-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderInvoiceConfirmationModal } from '@cashbox-app/modal/order-invoice-confirmation/order-invoice-confirmation.modal';
import { TranslocoService } from '@ngneat/transloco';
import { orderStatusMap } from '@cashbox-app/utils/util';
import { ToastrService } from 'ngx-toastr';

interface SelectableOrder extends DeliveryOrderWithProducts {
  selected?: boolean;
}

interface Bucket extends DeliveryOrderWithProductsBucketModel {
  orders: SelectableOrder[];
}

/**
 * Component of Delivery list order by time
 */
@Component({
  selector: 'app-order-delivery.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.css']
})
export class OrderDeliveryComponent implements OnInit {
  readonly actionList = orderStatusMap;
  couriers: UserBasicModel[] = [];
  isLoading: { list: boolean, pack: boolean } = {
    list: false,
    pack: false
  };
  deliveryBuckets: Bucket[] = [];

  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1
  };
  isAll = false;
  // query params
  query?: string;
  date?: string;
  limit = 20;
  courierUserId?: number;
  actions: OrderAction[] = [];

  limits = [20, 30, 50, 100];
  private sub!: Subscription;
  private readonly defaultActions = [OrderAction.CREATED, OrderAction.PACKED, OrderAction.DECORED];

  constructor(
    private cashboxService: CashboxService,
    private auth: HxAuthService,
    private router: Router,
    private modal: NgbModal,
    private aRoute: ActivatedRoute,
    private errorService: ErrorHandlerService,
    private toastr: ToastrService,
    private tr: TranslocoService
  ) {
    // 'CREATED,MODIFIED,PACKED,DECORED'
  }

  ngOnInit() {
    this.loadCourierList();
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      this.query = paramMap.get('query') ?? undefined;
      this.date = paramMap.get('date') ?? isoDate(Date.now());
      this.limit = paramMap.get('limit') ? Number(paramMap.get('limit')) : 20;
      this.courierUserId = paramMap.get('courierUserId') ? Number(paramMap.get('courierUserId')) : undefined;
      this.actions = paramMap.get('actions')?.split(',').map(s => s as OrderAction) ?? this.defaultActions;
      this.loadList();
    });
  }

  pageChanged(event = +this.pagination.currentPage) {
    this.pagination.currentPage = event;
    this.navigateUrl(event);
  }

  getTypeFilter(): void {
    this.pageChanged(1);
  }

  resetFilter() {
    this.date = isoDate(Date.now());
    this.query = undefined;
    this.actions = this.defaultActions;
    this.courierUserId = undefined;
    this.limit = 20;
    this.getTypeFilter();
  }

  checkAll(): void {
    this.deliveryBuckets.forEach(bucket => bucket.orders.forEach(order => order.selected = this.isAll));
  }

  packOrders() {
    this.isLoading.pack = true;

    const orders = this.deliveryBuckets
      .map(bucket => bucket.orders.filter(order => order.selected))
      .reduce((a, b) => a.concat(b), []);
    const selectedIds: number[] = orders.map(bo => bo.id);
    const legalOrders = orders.filter(bo => bo.clientType === Entity.LEGAL);

    const packOrdersFn = async () => {
      if (selectedIds.length) {
        try {
          await this.cashboxService.packOrders(selectedIds);
          this.loadList();
          this.isAll = false;
          this.toastr.success('orders.packed.success');
        } catch (err: any) {
          if (err.error?.message === 'paymentInvoice.notGenerated') {
            const orderNumbers = err.error.data.numbers;
            this.toastr.error(this.tr.translate('order-delivery.noInvoicePay', {orderNumbers: orderNumbers}));
          } else if (err.error?.message === 'letterOfAuthority.notUploaded') {
            const orderNumbers = err.error.data.numbers;
            this.toastr.error(this.tr.translate('order-delivery.notUploadAuthorization', {orderNumbers: orderNumbers}));
          } else {
            this.errorService.handle(err);
          }
        } finally {
          this.isLoading.pack = false;
        }
      } else {
        this.isLoading.pack = false;
        this.toastr.warning('orders.not.selected');
      }
    };

    if (legalOrders.length > 0) {
      const modalRef = this.modal.open(OrderInvoiceConfirmationModal);
      modalRef.componentInstance.orderNumbers = legalOrders.map(bo => bo.uniqueNumber).join(', ');
      modalRef.result.then(() => {
        packOrdersFn();
      }, () => {
        this.isLoading.pack = false;
      });
    } else {
      packOrdersFn();
    }
  }

  toggleOrder(order: SelectableOrder): void {
    order.selected = !order.selected;
  }

  printOrders(): void {
    let selectedIds: number[] = [];
    this.deliveryBuckets
      .map(bucket => bucket.orders.filter(bo => bo.selected).map(bo => bo.id))
      .forEach(ids => selectedIds = selectedIds.concat(ids as number[]));
    if (selectedIds.length) {
      const checks: ReceiptModel[] = selectedIds.map(id => ({orderId: id} as ReceiptModel));
      const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
      modalInstance.componentInstance.checks = checks;
      modalInstance.componentInstance.isView = true;
      this.deliveryBuckets
        .map(bucket => bucket.orders.filter(order => order.selected).forEach(order => order.selected = false));
      this.isAll = false;
    } else {
      this.toastr.warning('orders.not.selected');
    }
  }

  onActionsChanged() {
    this.getTypeFilter();
  }

  private navigateUrl(page: number) {
    const params: Params = {
      page: page,
      actions: this.actions.length ? this.actions.join(',') : [],
      limit: this.limit
    };
    if (this.query) {
      params['query'] = this.query;
    }
    if (this.courierUserId) {
      params['courierUserId'] = this.courierUserId;
    }
    if (this.date) {
      params['date'] = this.date;
    }
    this.router.navigate(['./'], {
      queryParams: params,
      relativeTo: this.aRoute
    });
  }

  private loadCourierList(): void {
    this.auth.getStoreUsersByComponents({components: [ComponentType.delivery]})
      .subscribe(couriers => this.couriers = couriers);
  }

  private async loadList(page = this.pagination.currentPage) {
    this.isLoading.list = true;
    this.deliveryBuckets = [];
    try {
      const {list, count} = await this.cashboxService.getDeliveryOrderList({
        limit: this.limit,
        page: page,
        courierUserId: this.courierUserId,
        actions: this.actions,
        date: this.date ?? isoDate(Date.now()),
        query: this.query,
      });
      this.deliveryBuckets = list;
      this.pagination.allItemCount = count;
    } catch (err: any) {
      this.errorService.handle(err.error);
    } finally {
      this.isLoading.list = false;
    }
  }
}
