import { Component, Input } from '@angular/core';
import { StoreProductModel } from 'hx-services';

@Component({
  selector: 'app-store-product',
  templateUrl: './store-product.component.html',
  styleUrls: ['./store-product.component.css']
})
export class StoreProductComponent {
  @Input() product!: StoreProductModel;
}
