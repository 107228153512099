<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'settings.title' | transloco }}
      </h3>
    </div>
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row align-items-center">
            <div class="col-lg-2 col-md-3">
              {{ 'settings.numberСhecks' | transloco }}:
            </div>

            <div class="col-md-2">
              <input type="number" [(ngModel)]="countOfPrint" (ngModelChange)="updatePrintValue()" class="form-control m-input">
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-2 col-md-3">
              {{ 'settings.viewChecks' | transloco }}:
            </div>

            <div class="col-md-2">
              <span class="m-switch m-switch--icon m-switch--outline m-switch--success ">
                <label>
                  <input type="checkbox" id="printNote" [(ngModel)]="printNote" (ngModelChange)="updatePrintNote()">
                  <span></span>
                </label>
              </span>
            </div>
          </div>

          <div class="row align-items-center" *ngIf="showKktPrintOption">
            <div class="col-lg-2 col-md-3">
              {{ 'settings.printChecks' | transloco }}:
            </div>

            <div class="col-md-2">
              <span class="m-switch m-switch--icon m-switch--outline m-switch--success ">
                <label>
                  <input type="checkbox" id="kktPrint" [(ngModel)]="kktPrint" (ngModelChange)="updateKktPrintValue()">
                  <span></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
