import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { KindType, SalePriceModel, SaleProductModel } from 'hx-services';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { CashboxService } from '@cashbox-app/service/cashbox.service';

/**
 * List of default and optional products
 */
@Component({
  selector: 'app-product-kind-list',
  templateUrl: './product-kind-list.component.html',
  styleUrls: ['./product-kind-list.component.css']
})
export class ProductKindListComponent implements OnChanges {
  @Input() currencySymbol!: string;
  @Input() storeId?: number;

  @Output() productAdded = new EventEmitter<{ item: SaleProductModel, price: SalePriceModel }>();

  products: SaleProductModel[] = [];
  isLoading = {
    list: true,
  };

  constructor(
    private errorService: ErrorHandlerService,
    private cashboxService: CashboxService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.storeId) {
      this.loadProducts(this.storeId);
    }
  }

  addToCart(item: SaleProductModel, price: SalePriceModel) {
    this.productAdded.emit({
      item: item,
      price: price
    });
  }

  private async loadProducts(storeId: number) {
    this.products = [];
    this.isLoading.list = true;
    try {
      this.products = await this.cashboxService.getSaleProducts({storeId: storeId, kinds: [KindType.DEFAULT, KindType.OPTIONAL]});
    } catch (err: any) {
      this.errorService.handle(err);
    } finally {
      this.isLoading.list = false;
    }
  }
}
