import { Component, OnInit } from '@angular/core';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { CASHBOX_SETTINGS_LOCALPRINTNOTE, HxAuthService } from 'hx-services';
import { HC_KKT_PRINT, HC_PRINT_NUMBER } from '@cashbox-app/utils/util';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  countOfPrint!: number;
  kktPrint = false;
  showKktPrintOption = false;
  printNote = false;

  constructor(
    private fiscalizationService: FiscalizationService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit(): void {
    this.countOfPrint = Number(localStorage.getItem(HC_PRINT_NUMBER) ?? '1');
    this.kktPrint = localStorage.getItem(HC_KKT_PRINT) === 'true';
    this.fiscalizationService.getFiscalInfoByStoreId(this.auth.user.store.id).subscribe(fiscalInfo => this.showKktPrintOption = fiscalInfo.deviceBrand === 'АТОЛ');
    this.printNote = localStorage.getItem(CASHBOX_SETTINGS_LOCALPRINTNOTE) === 'true';
  }

  updatePrintValue() {
    localStorage.setItem(HC_PRINT_NUMBER, JSON.stringify(this.countOfPrint || 1));
  }

  updateKktPrintValue() {
    localStorage.setItem(HC_KKT_PRINT, JSON.stringify(this.kktPrint));
  }

  updatePrintNote() {
    localStorage.setItem(CASHBOX_SETTINGS_LOCALPRINTNOTE, JSON.stringify(this.printNote));
  }
}
