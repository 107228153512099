import { Component, OnDestroy, OnInit } from '@angular/core';
import { Notification } from 'hx-services';
import { HcNotificationService } from '@cashbox-app/service/hc-notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hc-alert',
  templateUrl: './hc-alert.component.html',
  styleUrls: ['./hc-alert.component.css']
})
export class HcAlertComponent implements OnInit, OnDestroy {
  events: Array<Notification> = [];
  private sub!: Subscription;

  constructor(
    private notificationService: HcNotificationService,
  ) { }

  ngOnInit() {
    this.sub = this.notificationService.eventAlertObs.subscribe(
      result => {
        this.events = this.getAlerts(result);
        this.markAsSeen();
      },
      err => console.error(err)
    );
  }

  markAsSeen() {
    const ids = this.events.filter(el => !el.seenDate).map(el => el.id);
    if (ids.length) {
      this.notificationService.markAsSeen(ids);
    }
  }

  closeAlert(item: Notification, index: number) {
    this.notificationService.markAsRead([item.id]);
    this.events.splice(index, 1);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private getAlerts(arr: Notification[]): Notification[] {
    if (!arr) {
      return [];
    }

    return arr.filter(el => !el.readDate);
  }
}
