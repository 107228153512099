<div class="modal-header modal-header--brand">
  <h3 class="modal-title">{{ 'invoice-modal.invoice' | transloco }}</h3>

  <button class="close" type="button" (click)="modal.dismiss()">
    <span>×</span>
  </button>
</div>

<div class="modal-body m--padding-top-0" *ngIf="invoice">
  <ng-container *ngIf="!isReject">
    <div class="product-wrap m--margin-bottom-20" *ngIf="invoice.products && invoice.products.length && !isLoading.invoice">
      <ng-container *ngFor="let product of invoice.products">
        <app-invoice-product class="product-wrap__item" [product]="product"></app-invoice-product>
      </ng-container>
    </div>

    <hx-loader [isLoading]="isLoading.invoice" [length]="invoice.products.length"></hx-loader>

    <ng-container *ngIf="invoice.description">
      <h3 class="hc-title hc-title--small">{{ 'balance-new.note' | transloco }}</h3>
      <div class="text-left m--margin-top-15">
        <div class="product-wrap m--margin-bottom-20">
          {{ invoice.description }}
        </div>
      </div>
    </ng-container>

    <h3 class="hc-title hc-title--small">{{ 'invoice-modal.info' | transloco }}</h3>

    <app-invoice-events [id]="invoice.id"></app-invoice-events>

    <div class="text-right m--margin-top-15">
      <button *ngIf="canAccept" type="button" [disabled]="isLoading.accept" (click)="accept()"
              class="hc-button hc-button--success m--margin-right-10">
        {{ 'buttons.invoice' | transloco }}
      </button>

      <button *ngIf="canCancel" type="button" (click)="isReject = true;" class="hc-button hc-button--danger">
        {{ cancelButtonLabel }}
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isReject">
    <label>
      {{ 'invoice-modal.cause' | transloco }}:
    </label>

    <textarea class="form-control m-input" [(ngModel)]="cancelReason"></textarea>

    <div class="text-right m--margin-top-15">
      <button type="button" [disabled]="isLoading.rejectOrCancel" (click)="rejectOrCancelInvoice()"
              class="hc-button hc-button--success m--margin-right-10">
        {{ 'invoice-modal.ok' | transloco }}
      </button>

      <button type="button" (click)="isReject = false;" class="hc-button hc-button--secondary">
        {{ 'invoice-modal.no' | transloco }}
      </button>
    </div>
  </ng-container>
</div>
