import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { AppEventModel, AppEventType, HxEventService } from 'hx-services';

/**
 * Component of invoice event list
 *
 * @example  <app-invoice-events [id]="someId"></app-invoice-events>
 */
@Component({
  selector: 'app-invoice-events',
  templateUrl: './invoice-events.component.html',
})
export class InvoiceEventsComponent implements OnChanges {
  @Input() id?: number;
  events: AppEventModel[] = [];
  eventList = new Map<AppEventType, string>([
    [AppEventType.ORDER_CREATED, 'info'],
    [AppEventType.ORDER_MODIFIED, 'brand'],
    [AppEventType.ORDER_PACKED, 'warning'],
    [AppEventType.ORDER_DECORED, 'focus'],
    [AppEventType.ORDER_SHIPPED, 'warning'],
    [AppEventType.ORDER_RECEIVED, 'success'],
    [AppEventType.ORDER_CANCELLED, 'danger'],
    [AppEventType.ORDER_PAID, 'success'],
    [AppEventType.ORDER_TAKEN, 'warning'],
    [AppEventType.ORDER_RETURNED, 'metal'],
    [AppEventType.ORDER_REFUNDED, 'danger',],
    [AppEventType.PRODUCT_REFUNDED, 'metal',],
    [AppEventType.ORDER_UNARCHIVED, 'light'],
    [AppEventType.INVOICE_DISCARDED, 'danger'],
    [AppEventType.INVOICE_NEW, 'info'],
    [AppEventType.INVOICE_ACCEPTED, 'success'],
    [AppEventType.INVOICE_REJECTED, 'danger'],
    [AppEventType.INVOICE_SENT, 'warning'],
    [AppEventType.INVOICE_CANCELLED, 'danger'],
  ]);

  constructor(
    private eventService: HxEventService,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id) {
      this.loadEvents(this.id);
    }
  }

  private loadEvents(id: number) {
    this.eventService.getInvoiceEventList(id).subscribe(events => this.events = events,
      err => this.errorService.handle(err)
    );
  }

}
