import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-cancel-reason',
  templateUrl: './cancel-reason.component.html',
  styleUrls: ['./cancel-reason.component.css']
})
export class CancelReasonComponent implements OnInit{
  @Output() cancelReasonChange = new EventEmitter<string>();

  cancelReasonList: string[] = [];
  cancelReason = '';
  cancelReasonText?: string;

  constructor(private tr: TranslocoService) { }

  ngOnInit(): void {
    this.cancelReasonList = [
      `${this.tr.translate('cancel-reason.text-1')}`,
      `${this.tr.translate('cancel-reason.text-2')}`,
      `${this.tr.translate('cancel-reason.text-3')}`,
      `${this.tr.translate('cancel-reason.text-4')}`,
    ];
  }

  updateData() {
    const cancelReason = this.cancelReason === 'otherReason' ? this.cancelReasonText : this.cancelReason;
    this.cancelReasonChange.emit(cancelReason);
  }

  setCancelReason(reason: string) {
    this.cancelReason = reason;
    this.updateData();
  }
}
