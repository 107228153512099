import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { DateRange, DeliveryOrderResponse, extractDateRange, extractNumber, HxReportService, PaymentType, SortType, toRouterQueryParams } from 'hx-services';
import { Subscription } from 'rxjs';
import { orderStatusMap } from '@cashbox-app/utils/util';

interface MenuItem {
  title: string;
  sort?: 'asc' | 'desc'
  orderBy: string;
}

@Component({
  selector: 'app-user-order.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.css']
})
export class UserOrderComponent implements OnInit, OnDestroy {
  list: DeliveryOrderResponse[] = [];
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1
  };
  isLoading = {
    list: true
  };
  orderBy = 'unique_number';
  sort: 'asc' | 'desc' = 'desc';
  sortable = true;
  menu: MenuItem[] = [
    {
      title: 'id',
      sort: 'desc',
      orderBy: 'id'
    },
    {
      title: 'номер',
      sort: 'desc',
      orderBy: 'number'
    },
    {
      title: 'дата доставки',
      orderBy: 'date'
    }
  ];
  readonly statusList = orderStatusMap;
  params: {
    sale?: number;
    employee?: number;
    date?: DateRange;
    limit?: number;
    page?: number;
    orderBy?: string;
    sort?: SortType;
    paymentType?: PaymentType;
    debt?: boolean;
    discount?: boolean;
    storeIds: number[];
  } = {storeIds: []};

  private sub!: Subscription;

  constructor(
    private aRoute: ActivatedRoute,
    private router: Router,
    private reportService: HxReportService,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnInit() {
    this.sub = this.aRoute.queryParamMap.subscribe(paramMap => {
      const asBooleanFn = (name: string) => paramMap.get(name) ? paramMap.get(name) === 'true' : undefined;
      this.params.orderBy = paramMap.get('orderBy') ?? undefined;
      this.params.paymentType = paramMap.get('paymentType') as PaymentType;
      this.params.discount = asBooleanFn('discount');
      this.params.date = extractDateRange('date', paramMap);
      const storeId = extractNumber('storeId', paramMap);
      if (storeId) {
        this.params.storeIds = [storeId];
      }
      this.params.sale = extractNumber('sale', paramMap);
      this.params.page = extractNumber('page', paramMap) ?? 1;
      this.getList();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  sortTable(item: MenuItem): void {
    this.orderBy = item.orderBy;
    if (item.sort) {
      this.sort = item.orderBy === 'desc' ? 'asc' : 'desc';
    } else {
      this.sort = 'desc';
      this.menu.forEach(el => {
        if (el.orderBy !== item.orderBy) {
          el.sort = undefined;
        }
      });
    }
    this.list = [];
    item.sort = this.sort;
    this.pageChanged(1);
  }

  pageChanged(event = +this.pagination.currentPage) {
    this.navigate(event);
  }

  private getList() {
    this.isLoading.list = true;
    this.list = [];

    this.reportService.getOrderReport(this.params).then(result => {
      this.list = result.list;
      this.pagination.allItemCount = result.count;
      this.isLoading.list = false;
    }, err => {
      this.errorService.handle(err);
      this.isLoading.list = false;
    });
  }

  private navigate(page: number) {
    this.params.page = page;
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }
}
