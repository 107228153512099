<table class="table" *ngIf="cart.length">
  <thead>
    <tr>
      <th>{{ 'table.product' | transloco }}</th>
      <th class="text-center">{{ 'table.number' | transloco }}</th>
      <th class="text-right">{{ 'table.sum' | transloco }}</th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let i = index; let cartItem of cart; trackBy: trackByFn">
      <td>
        {{ cartItem.title | uiLabel }} <span *ngIf="cartItem.amount !== 1">{{ cartItem.amount }}</span>
      </td>

      <td style="width: 115px;">
        <div class="input-group m-form__group">
          <span class="input-group-addon" (click)="downAmount(cartItem, i)">
            <i class="la la-minus"></i>
          </span>

          <input type="number" class="form-control text-center form-control--number" [(ngModel)]="cartItem.quantity" readonly>

          <span class="input-group-addon" (click)="upAmount(cartItem)">
            <i class="la la-plus"></i>
          </span>
        </div>
      </td>

      <td class="text-right">
        <span class="m-widget1__number m--font-bold">
          {{ cartItem.total | uiCurrency:currency }}
        </span>
      </td>

      <td class="m--padding-right-0">
        <a (click)="removeFromCart(cartItem, i)" class="m--remove-product" [ngbTooltip]="'table.deleteProduct' | transloco">
          <i class="la la-trash"></i>
        </a>
      </td>
    </tr>

    <tr>
      <td>{{ 'table.total' | transloco }}</td>
      <td class="text-center">{{totalItem}}</td>
      <td class="text-right">
        {{ orderSubTotal | uiCurrency:currency }}
      </td>
      <td></td>
    </tr>

    <tr *ngIf="discounts.length">
      <td colspan="4"> <strong>{{ 'cart-list.discounts' | transloco}}</strong></td>
    </tr>

    <tr class="is--discount" [ngClass]="{'is--disabled': !discount.enabled }"
      *ngFor="let discount of discounts; let i = index">
      <td colspan="2" class="m--padding-left-20">
        {{ discount.promoTitle | uiLabel }}
        <span *ngIf="discount?.priceId">-{{ checkProduct(discount.priceId) }} </span>
      </td>

      <td class="text-right">
        <!-- TODO @deedarb #ins other result types ? -->
        <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} %</span>
        <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: currency }}</span>
      </td>

      <td class="m--padding-right-0">
        <button (click)="toggleDiscount(discount)" [disabled]="isLoading.discounts" class="btn btn-sm btn-outline-light" [ngClass]="{'m--remove-product': discount.enabled, 'm--enable-product': !discount.enabled}"
                [ngbTooltip]="(discount.enabled ? 'table.cancelDiscount' : 'table.applyDiscount') | transloco">
          <i class="la" [ngClass]="{'la-ban': discount.enabled, 'la-check': !discount.enabled}"></i>
        </button>
      </td>
    </tr>

    <tr *ngIf="discounts.length">
      <td>{{ 'cart-list.total' | transloco}}</td>
      <td class="text-center">{{ totalItem }}</td>
      <td class="m--font-focus text-right" style="font-size: 16px;">
        {{ orderTotal | uiCurrency:currency }}
      </td>
      <td></td>
    </tr>
  </tbody>
</table>
