import { Component, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { HxProductSplitDefinitionService, ProductSplitAvailableShapeModel, ProductSplitDefinitionFullModel } from 'hx-services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';

interface ProductSplitInfo extends ProductSplitDefinitionFullModel {
  transform?: string;
  transformLast?: string;
  conicGradient?: string;
  progressRotate?: string[];
  shapes: string[];
  enabled?: boolean;
}

interface StyleInfo {
  transform?: string;
  transformLast?: string;
  conicGradient?: string;
  progressRotate?: string[]
}

@Component({
  selector: 'app-split-product-modal',
  templateUrl: './split-product.modal.html',
  styleUrls: ['./split-product.modal.css']
})
export class SplitProductModal implements OnDestroy, OnInit {
  @Input() splitProductList!: ProductSplitInfo[];
  quantityArr: ProductSplitInfo[] = [];
  selectedProductSplit?: ProductSplitInfo;
  availableShapes?: ProductSplitAvailableShapeModel;
  isLoading = {
    cancel: false,
    split: false,
  };
  colors = ['#ffb822', '#e0a019', '#c98f15', '#bb8512', '#ffb822', '#e0a019', '#c98f15', '#bb8512', '#ffb822', '#e0a019'];
  private $destroyed = new Subject<void>();

  constructor(
    private productSplitDefinitionService: HxProductSplitDefinitionService,
    private modal: NgbModal,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.splitProductList.sort((a, b) => a.splitQuantity - b.splitQuantity);
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  trackById: TrackByFunction<any> = (index: number, obj: { id: number }) => obj.id;

  dismissModal(reason?: string) {
    this.modalInstance.dismiss(reason);
  }

  chooseQuantity(sp: ProductSplitInfo) {
    this.selectedProductSplit = undefined;
    this.splitProductList.forEach(r => r.enabled = false);
    sp.enabled = true;

    this.productSplitDefinitionService.getAvailableShapes({productSplitDefinitionId: sp.id}).subscribe({
      next: result => {
        this.availableShapes = result;
        this.quantityArr = [];
        this.availableShapes.shapes.forEach(shapes => {
          const style = this.getStyles(shapes);
          this.quantityArr.push({
            id: sp.id,
            transform: style.transform,
            transformLast: style.transformLast,
            conicGradient: style.conicGradient,
            progressRotate: style.progressRotate,
            shapes: shapes,
            splitQuantity: sp.splitQuantity,
            inProductInfo: sp.inProductInfo,
            outProductInfo: sp.outProductInfo,
            shape: sp.shape,
            inProductSize: sp.inProductSize,
          });
        });
      }
    });
  }

  onQuantitySelected(sp: ProductSplitInfo) {
    this.selectedProductSplit = sp;
  }

  split() {
    if (!this.selectedProductSplit) {
      throw new Error('selectedProductSplit.undefined');
    }
    this.isLoading.split = true;
    this.productSplitDefinitionService.splitProcess({
      productSplitDefinitionId: this.selectedProductSplit.id,
      shapes: this.selectedProductSplit.shapes
    }).subscribe({
      next: () => {
        this.isLoading.split = false;
        this.toastr.success(this.tr.translate('split-product-modal.separatedProducts'));
        this.dismissModal();
      },
      error: err => {
        this.isLoading.split = false;
        this.errorHandler.handle(err);
      }
    });
  }

  cancelSplit() {
    this.isLoading.cancel = true;
    this.productSplitDefinitionService.cancelActiveCart().subscribe({
      next: () => {
        this.isLoading.cancel = false;
        this.toastr.success(this.tr.translate('split-product-modal.canceledDivision'));
        this.dismissModal();
      },
      error: () => this.isLoading.cancel = false
    });
  }

  private getStyles(shapes: string[]): StyleInfo {
    let styleInfo: StyleInfo = {};
    let part: number[] = [];
    let startPoint = 0;
    shapes.forEach(element => part.push(Number(element.split('/')[1])));
    part.sort((a, b) => a - b);
    const arrStr = [];
    let injection = 0;
    for (let i = 0; i < shapes.length; i++) {
      let circleDeg = -360 / part[i];
      startPoint += circleDeg;
      injection = 360 + startPoint;
      arrStr.push('rotate(' + startPoint + 'deg)');
    }
    styleInfo.progressRotate = arrStr;
    styleInfo.conicGradient = 'conic-gradient(from ' + (injection) + 'deg, #F5F5DC ' + (-startPoint) + 'deg, ' + 'white ' + (-startPoint) + 'deg ' + (360) + 'deg )';
    styleInfo.transform = 'rotate(' + startPoint / 2 + 'deg)';
    if (startPoint > -360) {
      styleInfo.transformLast = 'rotate(0deg); left: 50px;';
    }
    return styleInfo;
  }
}
