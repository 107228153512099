import { Component, HostListener, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { FiscalProvider, HxAuthService } from 'hx-services';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}`;
  isFiscalizationVisible = false;
  isFiscalPaperReady = true;

  constructor(
    private keycloakService: KeycloakService,
    private fiscalizationService: FiscalizationService,
    private cashboxService: CashboxService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit(): void {
    this.fiscalizationService.getFiscalInfoByStoreId(this.auth.user.store.id).subscribe(fiscalInfo => {
      if (fiscalInfo.enabled && fiscalInfo.provider === FiscalProvider.PLATFORMA_OFD) {
        this.isFiscalizationVisible = true;
        this.loadFiscalPaperReady();
      }
    });

    this.cashboxService.fiscalPaperReadyObs.pipe(debounceTime(700)).subscribe(() => {
      this.loadFiscalPaperReady();
    });
  }

  @HostListener('document:click', ['$event'])
  handleKeyboardEvent(event: any): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && event.target.className.indexOf('open-sidebar') === -1) {
      this.toggleMobSidebar();
    }
  }

  toggleMobSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }
  }

  logout() {
    this.keycloakService.logout();
  }

  private loadFiscalPaperReady() {
    this.cashboxService.getFiscalPaperState().then(result => {
      this.isFiscalPaperReady = result.ready;
    });
  }
}
