<div *ngIf="!isOrderPaid" class="justify-content-between">
  <ng-container *ngIf="paymentType === 'KASPI'">
    <p>
      {{ 'hc-pay.textPhone' | transloco }}
    </p>

    <div class="pay-invoice">
      <input [(ngModel)]="clientPhone" [mask]="'(000) 000-00-00'" [prefix]="'+7 '" class="form-control" type="tel"/>
      <button class="btn btn-outline-primary" [disabled]="isLoading.sendPayInvoice" (click)="sendPayInvoice()">
        <i *ngIf="isLoading.sendPayInvoice" class="la la-spinner la-spin"></i>
        {{ 'buttons.send' | transloco }}
      </button>
    </div>

    <p>
      <span [innerHTML]="'hc-pay.textMain' | transloco"></span>
      <strong style="margin-left: 0.5rem;">{{order.uniqueNumber}}</strong>
    </p>
  </ng-container>

  <button type="button" class="btn btn-success" (click)="checkPaid()">{{ 'hc-pay.statusPayment' | transloco }}</button>
</div>
