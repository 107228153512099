<app-header></app-header>

<div class=" m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
  <app-sidebar class="m-grid__item m-aside-left  m-aside-left--skin-dark"></app-sidebar>
  <!-- <div class="m-aside-left-overlay m-menu__link"></div> -->

  <div class="hc-main-content">
    <hc-alert></hc-alert>

    <router-outlet></router-outlet>
  </div>
</div>

<hc-curbside-pickup></hc-curbside-pickup>

<app-footer></app-footer>
