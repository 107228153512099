import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { HcNotificationService } from '@cashbox-app/service/hc-notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Notification, NotificationType } from 'hx-services';
import { MessageCount } from './message-count.model';

@Component({
  selector: 'hc-notifier',
  templateUrl: './hc-notifier.component.html',
  styleUrls: ['./hc-notifier.component.css']
})
export class HcNotifierComponent implements OnInit, OnDestroy {
  @ViewChild('templateRef', {static: true}) templateRef!: TemplateRef<any>;
  @Output() update = new EventEmitter<boolean>();

  events: Array<Notification> = [];
  isDropdown = false;
  messageCount?: MessageCount;
  profileUrl = `${location.protocol}//profile.${location.hostname.split('.').slice(1).join('.')}/notification`;
  selectNotification?: Notification;
  private subEvent!: Subscription;
  private subCounter!: Subscription;

  constructor(
    private _eref: ElementRef,
    private notificationService: HcNotificationService,
    private modal: NgbModal,
  ) {
  }

  @HostListener('document:click', ['$event'])
  handleKeyboardEvent(event: any) {
    if (this.isDropdown) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.showDropdown();
      }
    }
  }

  ngOnInit() {
    this.subCounter = this.notificationService.eventCountObs.subscribe(result => {
      this.messageCount = result;
    });

    this.subEvent = this.notificationService.eventListObs.subscribe(
      events => {
        this.events = events.list || [];
      },
      err => {
        console.error('eventListObs::err', err);
      }
    );
  }

  ngOnDestroy() {
    if (this.subEvent) {
      this.subEvent.unsubscribe();
    }

    if (this.subCounter) {
      this.subCounter.unsubscribe();
    }
  }

  showDropdown() {
    this.isDropdown = !this.isDropdown;

    if (this.isDropdown && this.isMessagesUnseen()) {
      this.updateMessageCount();
    }

    this.update.emit(this.isDropdown);
  }

  private updateMessageCount() {
    const ids = this.events.map(el => el.id);
    this.markAsSeen(ids);
  }

  isMessagesUnseen(): boolean {
    return this.events.some(el => !el.seenDate);
  }

  markAsSeen(ids: Array<number>) {
    this.notificationService.markAsSeen(ids);
  }

  markAllAsRead() {
    const ids = this.events.map(el => el.id);
    this.markAsRead(ids);
  }

  markAsRead(ids: Array<number>) {
    this.notificationService.markAsRead(ids);
  }

  readNotification(item: Notification) {
    if (!item) {
      return;
    }

    this.showDropdown();
    this.markAsRead([item.id]);
    this.selectNotification = item;
    this.modal.open(this.templateRef, {size: 'sm'});
  }

  canShowItem(item: Notification): boolean {
    return item.enabled && !item.removed && item.type === NotificationType.INFO && !item.readDate;
  }

}
