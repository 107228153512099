import { Component, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import {
  CalculateDiscountRequest,
  Currency,
  DeliveryType,
  DiscountResultModel,
  Entity,
  getCurrencySymbol,
  HxAuthService,
  HxOrderService,
  PaymentHolder,
  PaymentType,
  ProductShortModel,
  ProductStatus,
  SourceSystem,
  toFixed,
  toPaymentArray
} from 'hx-services';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { format, parseISO } from 'date-fns';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

export interface ProductCancelOrder extends PaymentHolder {
  products: ProductShortModel[];
  total: number;
  subTotal: number;
  sourceSystem: SourceSystem;
  clientId?: number;
  clientType?: Entity
  storeId: number;
  date: string;
  discounts: DiscountResultModel[];
  id: number;
}

interface ProductItem extends ProductShortModel {
  enabled: boolean;
  beforeAmount: number;
  afterAmount: number;
  discounts: DiscountResultModel[];
}

/**
 * Component cancel order after sold. Edit order or cancel
 */
@Component({
  selector: 'app-order-product-cancel',
  templateUrl: './order-product-cancel.modal.html',
  styleUrls: ['./order-product-cancel.modal.css']
})
export class OrderProductCancelModal implements OnInit, OnDestroy {
  @Input() order!: ProductCancelOrder;
  hasPartialRefunds = false;

  products: ProductItem[] = [];
  isDisabled = false;
  isMixedPayment = false; // cash and pos
  currency!: Currency;
  currencySymbol!: string;
  isLoading = {
    discounts: false,
  };
  hasCoin = false;
  hasCash = false;
  hasPos = false;
  hasKaspiPos = false;
  private $calcSubject = new Subject<void>();
  private $destroyed = new Subject<void>();
  private cancelReason = '';

  constructor(
    private cashboxService: CashboxService,
    private orderService: HxOrderService,
    private toastr: ToastrService,
    private errorService: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
    private fiscalizationService: FiscalizationService,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  trackByFn: TrackByFunction<ProductItem> = (index, obj) => obj.id;

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.currency = this.auth.user.store.currency;
    this.currencySymbol = getCurrencySymbol(this.currency);
    this.resetPaymentValues();
    const payments = toPaymentArray(this.order);
    this.isMixedPayment = payments.filter(p => p.type !== PaymentType.COIN && p.value > 0).length > 1;
    this.hasCoin = payments.some(p => p.type === PaymentType.COIN);
    this.hasCash = payments.some(p => p.type === PaymentType.CASH);
    this.hasPos = payments.some(p => p.type === PaymentType.POS);
    this.hasKaspiPos = payments.some(p => p.type === PaymentType.KASPI_POS);

    this.products = this.order.products
      .filter(p => p.status === ProductStatus.RECEIVED)
      .map(p => ({
        ...p, ...{
          enabled: true,
          beforeAmount: p.amount,
          afterAmount: p.amount,
          discounts: (this.order.discounts ?? []).filter(dsc => dsc.productId === p.id),
        }
      }));

    this.$calcSubject.pipe(
      takeUntil(this.$destroyed),
      debounceTime(400),
      tap(() => this.isLoading.discounts = true)
    ).subscribe(() => this.doRecalcDiscounts());

    this.recalculateDiscounts();
  }

  onCancelReasonChange(reason: string) {
    this.cancelReason = reason;
  }

  async cancelOrder() {
    this.isDisabled = true;
    try {
      const {refundOrderId} = await this.cashboxService.cancelOrder(this.order.id, this.cancelReason);
      if (refundOrderId) {
        this.fiscalizationService.fiscalizeIfOffline(refundOrderId).then(isFiscalized => {
          if (isFiscalized) {
            this.toastr.info(this.tr.translate('order-product-cancel.refundFiscalized'));
          }
        });
      }
      this.modalInstance.close();
    } catch (err: any) {
      this.errorService.handle(err);
    } finally {
      this.isDisabled = false;
    }
  }

  addProduct(item: ProductItem) {
    if (item.afterAmount < item.beforeAmount) {
      item.afterAmount += 1;
      item.value += item.price.value;
      item.enabled = true;
      this.recalculateDiscounts();
    }
  }

  subtractProductAmount(item: ProductItem) {
    if (item.enabled && item.afterAmount > 0) {
      item.afterAmount -= 1;
      item.value -= item.price.value;
      if (item.afterAmount === 0) {
        item.enabled = false;
      }
      this.recalculateDiscounts();
    }
  }

  updateOrder() {
    if (this.isMixedPayment && toPaymentArray(this.order).reduce((a, b) => toFixed(a + b.value), 0) !== this.order.total) {
      this.toastr.warning(this.tr.translate('order-product-cancel.checkPayment'));
      return;
    }
    const hasChanges = this.products.some(item => item.afterAmount < item.beforeAmount);
    if (!hasChanges) {
      this.toastr.warning(this.tr.translate('order-product-cancel.noChanges'));
      return;
    }

    this.isDisabled = true;

    const products = this.products.filter(item => item.enabled)
      .map(item => ({priceId: item.price.id, amount: item.afterAmount, weight: item.weight}));
    const payments = toPaymentArray(this.order);
    if (!this.isMixedPayment) {
      const payment = payments.find(pt => pt.value > 0);
      if (payment) {
        payment.value = this.order.total;
      }
    }

    this.cashboxService.productsCancel(this.order.id, {
      payments: payments,
      discounts: this.order.discounts,
      subTotal: this.order.subTotal,
      total: this.order.total,
      products: products,
      reason: this.cancelReason,
    }).then(result => {
      if (result.refundOrderId) {
        this.fiscalizationService.fiscalizeIfOffline(result.refundOrderId).then(isFiscalized => {
          if (isFiscalized) {
            this.toastr.info(this.tr.translate('order-product-cancel.refundFiscalized'));
          }
        });
      }
      this.isDisabled = false;
      this.modalInstance.close();
    }, err => {
      this.isDisabled = false;
      this.errorService.handle(err);
    });
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }

  private recalculateDiscounts() {
    this.$calcSubject.next();
  }

  private doRecalcDiscounts() {
    const request: CalculateDiscountRequest = {
      results: this.order.discounts,
      order: {
        id: this.order.id,
        date: this.order.date.substring(0, 10),
        time: format(parseISO(this.order.date), 'HH:mm'),
        storeId: this.order.storeId,
        clientId: this.order.clientId,
        clientType: this.order.clientType ?? Entity.INDIVIDUAL,
        deliveryType: DeliveryType.PICKUP,
      },
      products: [],
      sourceSystem: this.order.sourceSystem,
    };

    request.products = this.products.filter(item => item.enabled)
      .map(item => ({
        amount: item.afterAmount,
        priceId: item.price.id,
      }));

    this.orderService.checkDiscount(request)
      .pipe(finalize(() => this.isLoading.discounts = false))
      .subscribe(result => {
        this.order.discounts = result.results;
        this.order.total = result.order.total;
        this.order.subTotal = result.order.subTotal;
        if (!this.isMixedPayment) {
          this.resetPaymentValues();
        }
      }, err => this.errorService.handle(err));
  }

  private resetPaymentValues() {
    const fields: (keyof PaymentHolder)[] = [
      'cash',
      'pos',
      'forte',
      'rahmet',
      'senim',
      'checkingAccount',
      'kaspi',
      'halyk',
      'kaspiPos',
      'paybox',
      'uzPaybox',
      'debt',
      'coin',
    ];
    fields.forEach(f => this.order[f] = this.order[f] ?? 0);
  }
}
