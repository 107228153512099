import { Component, OnInit } from '@angular/core';
import { HcNotificationService } from '@cashbox-app/service/hc-notification.service';
import { HxAuthService } from 'hx-services';

@Component({
  selector: 'app-home.h-100.m-grid.m-grid--hor.m-grid--root.m-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    private notificationService: HcNotificationService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    this.notificationService.start(this.auth.user.phone, this.auth.user.store.id);
  }
}
