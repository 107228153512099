import { Component, Input } from '@angular/core';
import { InvoiceProductModel } from 'hx-services';

@Component({
  selector: 'app-invoice-product',
  templateUrl: './invoice-product.component.html',
  styleUrls: ['./invoice-product.component.css']
})
export class InvoiceProductComponent {
  @Input() product!: InvoiceProductModel;
}
