import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction } from '@angular/core';
import { Currency, DiscountResultModel, HxOrderService, KindType, SalePriceModel, SaleProductModel, UiLabel } from 'hx-services';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';

export interface CartItem {
  id?: number;
  enabled?: boolean;
  productInfoId: number;
  amount: number;
  title: UiLabel;
  quantity: number;
  total: number;
  counter: number;
  value: number;
  priceId: number;
  priceAmount: number;
  type?: string;
}

@Component({
  selector: 'hc-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.css']
})
export class CartListComponent implements OnInit {
  @Input() cart!: CartItem[];
  @Input() orderId!: number;
  @Input() orderTotal!: number;
  @Input() orderSubTotal!: number;
  @Input() storeId!: number;
  @Input() currency!: Currency;
  @Input() discounts: DiscountResultModel[] = [];

  @Output() update = new EventEmitter<any>();
  @Output() checkDiscount = new EventEmitter<any>();

  isLoading = {
    discounts: false,
  };
  private defaultProducts = new Map<number, SaleProductModel>();

  constructor(private orderService: HxOrderService,
              private errorService: ErrorHandlerService,
              private cashboxService: CashboxService,
  ) {
  }

  ngOnInit() {
    this.cashboxService.getSaleProducts({kinds: [KindType.DEFAULT], storeId: this.storeId}).then(result => {
      result.forEach(saleProduct => this.defaultProducts.set(saleProduct.id, saleProduct));
    });
  }

  async upAmount(item: CartItem) {
    if (item.id) {
      try {
        const res = await this.cashboxService.addCartProduct(this.orderId, {priceId: item.priceId});
        item.counter += item.amount;
        item.quantity++;
        item.total += item.value;
        item.id = res.id;
        if (!this.defaultProducts.has(item.productInfoId)) {
          this.addDefaultProducts();
        }
        this.updateDiscount();
      } catch (err: any) {
        console.error('err', err);
        this.errorService.handle(err);
      }
    } else {
      item.counter += item.amount;
      item.quantity++;
      item.total += item.value;
      if (!this.defaultProducts.has(item.productInfoId)) {
        this.addDefaultProducts();
      }
      this.updateDiscount();
    }
  }

  downAmount(item: CartItem, index: number) {
    const counter = Math.ceil(item.amount);
    if (item.id) {
      if (item.quantity > counter) {
        this.orderService.downCartProduct(this.orderId, item.id).subscribe(() => {
          item.quantity--;
          item.total -= item.value;

          item.counter -= item.amount;
          if (!this.defaultProducts.has(item.productInfoId)) {
            this.subtractDefaultProducts(1);
          }
          this.updateDiscount();
        }, err => {
          console.error('err', err);
          this.errorService.handle(err);
        });
      } else {
        this.removeFromCart(item, index);
      }
    } else {
      if (item.quantity > counter) {
        item.quantity--;
        item.total -= item.value;

        item.counter -= item.amount;
        if (!this.defaultProducts.has(item.productInfoId)) {
          this.subtractDefaultProducts(1);
        }
        this.updateDiscount();
      } else {
        this.removeFromCart(item, index);
      }
    }
  }

  removeFromCart(cartItem: CartItem, index: number) {
    if (cartItem.id) {
      this.orderService.removeCartProduct(this.orderId, cartItem.id).subscribe(() => {
        this.cart.splice(index, 1);

        if (!this.cart.length) {
          this.resetOrder();
        }

        if (!this.defaultProducts.has(cartItem.productInfoId)) {
          this.subtractDefaultProducts(cartItem.quantity);
        }

        this.updateDiscount();
      }, err => {
        console.error('err', err);
        this.errorService.handle(err);
      });
    } else {
      this.cart.splice(index, 1);

      if (!this.cart.length) {
        this.resetOrder();
      }

      this.updateDiscount();
    }
  }

  disableDiscount(discount: DiscountResultModel) {
    discount.enabled = false;
    this.updateDiscount();
  }

  toggleDiscount(discount: DiscountResultModel): void {
    discount.enabled = !discount.enabled;
    this.updateDiscount();
  }

  get totalItem(): number {
    let total = 0;
    this.cart.forEach(item => {
      total = total + item.quantity;
    });

    return total;
  }

  checkProduct(priceId: number): string {
    return this.cart.find(item => item.priceId === priceId)?.title.ru ?? '';
  }

  resetOrder() {
    this.update.emit(this.cart);
  }

  updateDiscount() {
    this.checkDiscount.emit();
  }

  trackByFn: TrackByFunction<CartItem> = (index, obj) => obj.id;

  private subtractDefaultProducts(quantity: number) {
    const defaultCartProducts = this.cart.filter(cartProduct => this.defaultProducts.has(cartProduct.productInfoId));
    if (defaultCartProducts.length > 0) {
      defaultCartProducts.forEach(cartProduct => {
        if (cartProduct.quantity <= quantity) {
          const index = this.cart.indexOf(cartProduct);
          this.cart.splice(index, 1);
        } else {
          cartProduct.quantity -= quantity;
        }
      });
    }
  }

  private addDefaultProducts() {
    const defaultCartProducts = this.cart.filter(cartProduct => this.defaultProducts.has(cartProduct.productInfoId));
    if (defaultCartProducts.length > 0) {
      defaultCartProducts.forEach(cartProduct => {
        cartProduct.quantity += 1;
      });
    } else {
      this.defaultProducts.forEach(defaultProduct => {
        defaultProduct.prices.forEach(defaultPrice => {
          this.addToCart(defaultProduct, defaultPrice);
        });
      });
    }
  }

  private addToCart(item: SaleProductModel, price: SalePriceModel, productId?: number) {
    let isNew = true;

    const obj: CartItem = {
      id: productId,
      productInfoId: item.id,
      title: item.title,
      amount: price.amount,
      counter: price.amount,
      value: price.value,
      total: price.value,
      priceId: price.id,
      priceAmount: price.amount,
      quantity: 1,
    };

    this.cart.forEach(element => {
      if (price.id === element.priceId) {
        element.id = productId;
        element.counter += obj.amount;
        element.total += obj.value;
        element.quantity += obj.quantity;
        isNew = false;
      }
    });

    if (isNew) {
      this.cart.push(obj);
    }
  }
}
