<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'invoice-list.title' | transloco }}
      </h3>
    </div>
    <div>
      <button type="button" class="hc-button hc-button--success" (click)="exportBalance()">
        <span>
          {{ 'invoice-list.export' | transloco }}
        </span>
      </button>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <app-balance-view></app-balance-view>

          <h4 class="hc-title hc-title--small m--margin-bottom-20 m--margin-top-20">{{ 'invoice-list.overhead' | transloco }}</h4>

          <hr class="hr">

          <div class="row m--margin-bottom-20">
            <div class="col-md-3">
              <ng-select [(ngModel)]="invoiceStatus" (ngModelChange)="navigate()">
                <ng-option [value]="status" *ngFor="let status of invoiceStatusList">
                  {{ 'invoice.status.' + status | transloco }}
                </ng-option>
              </ng-select>
            </div>

            <div class="col-md-3">
              <hx-date-select [(ngModel)]="date" (selectChange)="applyFilters()"></hx-date-select>
            </div>

            <div class="col-md-2">
              <button (click)="resetFilter()" class="btn btn-primary hc-button hc-button--secondary" type="button">
                <hc-icon icon="refresh"></hc-icon>
                <span>{{ 'invoice-list.reset' | transloco }}</span>
              </button>
            </div>
          </div>

          <div class="is--responsive-table m--margin-top-30 m--margin-bottom-30" *ngIf="list.length && !isLoading.list">
            <table class="hc-table hc-table--brand">
              <thead class="hc-table__head">
                <tr class="hc-table__row hc-table__row--head">
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.dateSent' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.status' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.where' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.received' | transloco }}</span>
                  </th>
                  <th class="hc-table__cell">
                    <span>{{ 'invoice-list.description' | transloco }}</span>
                  </th>
                </tr>
              </thead>

              <tbody class="hc-table__body">
                <tr *ngFor="let item of list" class="hc-table__row" (click)="viewInvoice(item)">
                  <td class="hc-table__cell">
                    <hc-icon icon="clock"></hc-icon>
                    <span>{{ item.sendDate | date: 'dd.MM.yy / HH:mm' }}</span>
                  </td>

                  <td class="hc-table__cell">
                    <span class="m-badge m-badge--{{ statusList.get(item.status) ?? 'default' }} m-badge--wide m-badge--rounded">
                      {{ 'invoice.status.' + item.status | transloco }}
                    </span>
                  </td>

                  <td class="hc-table__cell">
                    <span class="m--margin-right-10">{{ item.fromStore?.title | uiLabel }}</span>
                    <hc-icon icon="left-arrow"></hc-icon>
                    <span>{{ item.toStore?.title | uiLabel }}</span>
                  </td>

                  <td class="hc-table__cell">
                    <span *ngIf="item.senderUser" class="m--margin-right-10" [ngbTooltip]="item.senderUser.fullname">{{ item.senderUser.fullname | fio }}</span>
                    <hc-icon icon="left-arrow" *ngIf="item.receiveUser"></hc-icon>
                    <span *ngIf="item.receiveUser" [ngbTooltip]="item.receiveUser.fullname">{{ item.receiveUser.fullname | fio }}</span>
                  </td>
                  <td class="hc-table__cell">
                    {{ item.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="list.length"></hx-loader>

          <div class="m--align-center" *ngIf="list.length && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="pagination.currentPage" [pageSize]="pagination.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
