import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import {
  AppEventType,
  DiscountsResponse,
  Entity,
  HxAuthService,
  HxDictionaryService,
  HxEventService,
  HxFileService,
  HxOrderService,
  HxStoreService,
  NotifierService,
  OrderAction,
  OrderResponse,
  OrderStatus,
  populatePaymentHolder,
  ReceiptModel,
  REFUND_DATE_LIMIT
} from 'hx-services';
import { Subject } from 'rxjs';
import { OrderCancelModal } from '@cashbox-app/modal/order-cancel/order-cancel.modal';
import { OrderProductCancelModal, ProductCancelOrder } from '@cashbox-app/modal/order-product-cancel/order-product-cancel.modal';
import { differenceInCalendarDays } from 'date-fns';
import { HxOrderReceiptComponent } from 'hx-component';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { CashboxOperationModal } from '@cashbox-app/modal/cashbox-operation/cashbox-operation.modal';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


/**
 * Component view order info
 */
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit, OnDestroy {
  isArchive = false;
  order!: OrderResponse;
  canAcceptPayment = false;
  canSellOrder = false;
  canCancelOrder = false;
  canCancelOrderProduct = false;
  isReceived = false;
  tooltip = {
    button: {
      showCancelOrderModal: '',
      showCancelOrderProductModal: '',
    }
  };
  private $destroyed = new Subject<void>();

  constructor(
    private modal: NgbModal,
    private aRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private storeService: HxStoreService,
    private domSanitizer: DomSanitizer,
    private errorHandlerService: ErrorHandlerService,
    private orderService: HxOrderService,
    private eventService: HxEventService,
    private fileService: HxFileService,
    private fiscalizationService: FiscalizationService,
    private notifierService: NotifierService,
    private auth: HxAuthService,
    private dictionary: HxDictionaryService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit() {
    const routeData: Data = this.aRoute.snapshot.data;
    this.order = routeData['order'];
    this.onOrderChanged(this.order);

    this.orderService.updateOrderObs.pipe(takeUntil(this.$destroyed))
      .subscribe(async () => {
        const orderResponse = await this.orderService.getFullOrderById(this.order.id);
        this.onOrderChanged(orderResponse);
      });

    this.aRoute.paramMap.pipe(takeUntil(this.$destroyed)).subscribe(async paramMap => {
      const id = Number(paramMap.get('id'));
      if (id !== this.order.id) {
        const orderResponse = await this.orderService.getFullOrderById(id);
        this.onOrderChanged(orderResponse);
      }
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  showCheck() {
    const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
    modalInstance.componentInstance.checks = [{orderId: this.order.id}];
    modalInstance.componentInstance.isView = true;
  }

  isRefundLimitExceeded(): boolean {
    const days = Math.abs(differenceInCalendarDays(new Date(this.order.paymentDate), Date.now()));
    return days >= REFUND_DATE_LIMIT;
  }

  async showCashboxModal(operation: 'paid' | 'sold' | 'sold-paid') {
    if (this.order.action === OrderAction.WAIT_PAYMENT) {
      this.toastr.error(this.tr.translate('order-view.nt.orderAwaiting'));
      return;
    }
    const showModalFn = async () => {
      const cashboxModal = this.modal.open(CashboxOperationModal, {size: 'x-full'});
      cashboxModal.componentInstance.orderId = this.order.id;
      cashboxModal.componentInstance.operation = operation;
      try {
        const result = await cashboxModal.result;
        if (result && result.success) {
          this.toastr.success(this.tr.translate(`order.${operation}.success`));
          this.fiscalizationService.fiscalizeIfOffline(this.order.id).then(() => {
            if (result.print) {
              this.printCheck();
            }
          });
        }
        this.updateOrder();
      } catch (err: any) {
        await this.orderService.cancelCart(this.order.id);
      }
    };
    if (operation === 'sold' && this.order.clientType === Entity.LEGAL) {
      this.eventService.getOrderEventList(this.order.id).subscribe(events => {
        if (events.some(ev => ev.type === AppEventType.COMPANY_INFO_SAVED)) {
          showModalFn();
        } else {
          this.toastr.error(this.tr.translate('order-view.nt.legalInfo.notCompleted'));
        }
      });
    } else {
      showModalFn();
    }
  }

  showCancelOrderModal(): void {
    if (this.isRefundLimitExceeded()) {
      return;
    }
    const cancelModal = this.modal.open(OrderCancelModal, {size: 'lg'});
    cancelModal.componentInstance.orderId = this.order.id;
    cancelModal.result.then(() => this.onOrderCancelled(), () => console.log('cancel operation prevented'));
  }

  showCancelOrderProductModal(): void {
    if (this.isRefundLimitExceeded()) {
      this.toastr.info(this.tr.translate('order-view.nt.refundDayLimit'));
      return;
    }
    const cancelOrderProduct: ProductCancelOrder = {
      id: this.order.id,
      date: this.order.date,
      discounts: [],
      products: [],
      sourceSystem: this.order.from,
      storeId: this.order.storeId,
      subTotal: this.order.subTotal,
      total: this.order.total,
      clientId: this.order.client?.id,
    };
    populatePaymentHolder(cancelOrderProduct, this.order.payment?.payments);

    cancelOrderProduct.products = this.order.products
      .filter(product => this.order.remainderProductAmounts[product.price.id] > 0)
      .map(product => Object.assign(JSON.parse(JSON.stringify(product)), {
        value: this.order.remainderProductAmounts[product.price.id] * product.price.value,
        amount: this.order.remainderProductAmounts[product.price.id]
      }));
    this.orderService.getDiscountList(this.order.id).then((result: DiscountsResponse) => {
      cancelOrderProduct.discounts = result.results;
      const cancelProductModal = this.modal.open(OrderProductCancelModal, {size: 'x-full'});
      cancelProductModal.componentInstance.order = cancelOrderProduct;
      // cancelProductModal.componentInstance.hasPartialRefunds = this.hasPartialRefunds;
      cancelProductModal.result.then(() => this.onOrderCancelled(), () => console.log('cancel operation prevented'));
    });
  }

  onOrderChanged(order: OrderResponse) {
    this.order = order;
    const isOrderRefunded = this.order.action === OrderAction.REFUNDED;
    this.isReceived = this.order.action === OrderAction.RECEIVED;
    this.canAcceptPayment = this.order.status !== OrderStatus.CART && !this.order.paid && !this.isFinished() && !this.order.debt;
    this.canSellOrder = !this.isFinished() && !this.isReceived && this.order.action !== OrderAction.REFUNDED && this.order.status !== OrderStatus.CART;
    this.canCancelOrder = this.canSellOrder && !isOrderRefunded;
    this.canCancelOrderProduct = this.isReceived && !isOrderRefunded && this.order.total > 0;
    this.tooltip.button.showCancelOrderModal = (this.isRefundLimitExceeded() ? 'order-view.btn.sell.tooltip' : (this.isReceived ? 'order-view.btn.orderReturn.tooltip' : 'order-view.btn.cancelOrder.tooltip'));
    this.tooltip.button.showCancelOrderProductModal = this.isRefundLimitExceeded() ? 'order-view.btn.sell.tooltip' : (this.order.paid ? 'order-view.btn.orderReturn.tooltip' : 'order-view.btn.cancelOrder.tooltip');
    this.isArchive = this.order.archived ?? false;
  }

  private updateOrder(): void {
    this.orderService.orderUpdated();
  }

  private printCheck(): void {
    this.orderService.printCheck({orderId: this.order.id} as ReceiptModel);
  }

  private isFinished(): boolean {
    return this.order.action === OrderAction.CANCELLED || this.order.status === OrderStatus.COMPLETED;
  }

  private onOrderCancelled() {
    this.toastr.success(this.tr.translate('order.canceled.success'));
    this.updateOrder();
  }
}
