import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateRange,
  extractDateRange,
  getCurrencySymbol,
  HxAuthService,
  HxReportService,
  HxStoreService,
  isoDate,
  PaymentInfoModel,
  PaymentType,
  RevenueModel,
  toRouterQueryParams
} from 'hx-services';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface MoneyReportRow extends RevenueModel {
  paymentMap: Map<PaymentType, PaymentInfoModel>;
}
/**
 * Component report list of users, how much sold orders and cash paid
 */
@Component({
  selector: 'app-money-report.m-grid__item.m-grid__item--fluid.m-wrapper',
  templateUrl: './money-report.component.html',
  styleUrls: ['./money-report.component.css']
})
export class MoneyReportComponent implements OnInit, OnDestroy {
  report?: MoneyReportRow;
  isLoading = {
    list: true,
  };
  dateRange?: DateRange;
  paymentTypes: PaymentType[] = [];
  paymentType = PaymentType;
  currencySymbol!: string;
  columns: PaymentType[] = [];
  currentStoreId!: number;
  private $destroyed = new Subject<void>();

  constructor(
    private errorService: ErrorHandlerService,
    private reportService: HxReportService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private storeService: HxStoreService,
    private auth: HxAuthService,
  ) { }

  ngOnInit() {
    this.dateRange = {from: isoDate(new Date()), to: isoDate(new Date())};
    const user = this.auth.user;
    this.currentStoreId = user.store?.id;
    this.currencySymbol = getCurrencySymbol(user.store.currency);
    this.loadActivePaymentTypes(user.store.id);
    this.aRoute.queryParamMap.pipe(takeUntil(this.$destroyed)).subscribe(async params => {
      this.dateRange = extractDateRange('date', params) ?? {from: isoDate(new Date()), to: isoDate(new Date())};
      await this.loadList();
    });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  applyFilters() {
    this.navigate();
  }

  async loadList() {
    this.isLoading.list = true;
    this.report = undefined;
    try {
      const list: RevenueModel[] = await this.reportService.getRevenue({ date: this.dateRange, storeIds: [this.auth.user.store.id] });
      const columns: PaymentType[] = [];
      const report = list.find(mr => mr.user?.id === this.auth.user.id);
      if (report) {
        report.payments = report.payments.filter(pi => pi.value && pi.value > 0);
        const paymentTypeOrder = Object.values(PaymentType);
        const paymentMap = new Map();
        report.payments.forEach(pi => {
          paymentMap.set(pi.type, pi);
          if (!columns.includes(pi.type) && pi.value !== 0) {
            columns.push(pi.type);
          }
        });
        this.columns = columns.sort((a, b) => paymentTypeOrder.indexOf(a) - paymentTypeOrder.indexOf(b));
        this.report = {...report, ...{paymentMap: paymentMap}};
      }
    } catch (err: any) {
      this.errorService.handle(err);
    } finally {
      this.isLoading.list = false;
    }
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams({date: this.dateRange}),
      relativeTo: this.aRoute
    });
  }

  private loadActivePaymentTypes(storeId: number): void {
    this.storeService.getActivePaymentTypes(storeId).subscribe(paymentTypes => {
      this.paymentTypes = paymentTypes;
    }, err => {
      this.errorService.handle(err.error);
    });
  }
}
