import { InvoiceStatus, OrderAction } from 'hx-services';

export const HC_PRINT_NUMBER = 'hc-print-number';
export const HC_KKT_PRINT = 'hc-kkt-print';

export const invoiceStatusMap = new Map<InvoiceStatus, string>([
  [InvoiceStatus.CANCELLED, 'danger'],
  [InvoiceStatus.NEW, 'info'],
  [InvoiceStatus.REJECTED, 'danger'],
  [InvoiceStatus.SHIPMENT, 'warning'],
  [InvoiceStatus.DISCARDED, 'focus'],
  [InvoiceStatus.SENT, 'info'],
]);
export const orderStatusMap = new Map<OrderAction, string>([
  [OrderAction.CREATED, 'info'],
  [OrderAction.PACKED, 'warning'],
  [OrderAction.DECORED, 'focus'],
  [OrderAction.SHIPPED, 'warning'],
  [OrderAction.RECEIVED, 'success'],
  [OrderAction.TAKEN, 'warning'],
  [OrderAction.RETURNED, 'metal'],
  [OrderAction.REFUNDED, 'metal'],
]);
