import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HeaderService } from './header.service';
import { environment } from '@cashbox-env/environment';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { HxAuthService, HxStoreService, HxUserService, StoreBasicModel, StoreFullModel, uiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';


/**
 * Header component: show info: store, shortname etc
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [HeaderService],
})
export class HeaderComponent implements OnInit {
  @ViewChild('templateRef', {static: true}) templateRef!: TemplateRef<any>;

  isOnline = window.navigator.onLine ?? true;
  fullname!: string;
  position!: string;
  open = false;
  submenu = false;
  isProd = environment.production;
  showDropdown = false;
  currentStore?: StoreBasicModel;
  stores: StoreFullModel[] = [];
  copyStores: StoreFullModel[] = [];
  query?: string;

  @HostListener('document:click', ['$event'])
  handleKeyboardEvent(event: any) {
    if (this.submenu) {
      if (!this._eref.nativeElement.contains(event.target)) {
        this.toggleSubmenu();
      }
    }
  }

  constructor(
    private _eref: ElementRef,
    private headerService: HeaderService,
    private toastr: ToastrService,
    private userService: HxUserService,
    private keycloakService: KeycloakService,
    private router: Router,
    public modal: NgbModal,
    private auth: HxAuthService,
    private storeService: HxStoreService,
    private tr: TranslocoService,
  ) {
    const ls = localStorage.getItem('sidebar');
    this.open = ls === 'minimize';
    if (this.open) {
      this.toggleSidebar();
    }
  }

  async ngOnInit() {
    this.checkNetwork();
    const user = this.auth.user;
    this.fullname = user.shortname ?? '';
    this.position = user.position ?? '';
    this.stores = (await this.auth.getComponentStores()).filter(store => store.enabled);
    this.currentStore = this.stores.find(item => item.id === this.auth.user.store.id);
    if (!this.currentStore) {
      throw new Error('currentStore.undefined');
    }

    this.stores = this.stores.sort((a, b) => (a.cityId < b.cityId) ? -1 : 1);
    this.copyStores = [...this.stores];
  }

  changeStore() {
    this.submenu = false;

    this.modal.open(this.templateRef, {size: 'sm'});
  }

  setStore(storeId: number) {
    this.userService.setDefaultStore(storeId).subscribe(() => {
      window.location.reload();
    });
  }

  toggleSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--minimize');

    if (hasClass !== -1 && !this.open) {
      body.classList.remove('m-aside-left--minimize');
      body.classList.remove('m-brand--minimize');
      this.setLs('');
    } else {
      body.classList.add('m-aside-left--minimize');
      body.classList.add('m-brand--minimize');
      this.setLs('minimize');
    }

    this.open = !this.open;
  }

  toggleMobSidebar(): void {
    const body = document.getElementsByTagName('body')[0];
    const hasClass = body.className.indexOf('--on');

    if (hasClass !== -1 && this.open) {
      body.classList.remove('m-aside-left--on');
    } else {
      body.classList.add('m-aside-left--on');
    }

    this.open = !this.open;
  }

  toggleSubmenu(): void {
    this.submenu = !this.submenu;
  }

  logout() {
    this.keycloakService.logout();
  }

  navigateUrl(url: string) {
    this.toggleSubmenu();
    this.router.navigateByUrl(`/${url}`);
  }

  reload() {
    location.reload();
  }

  search() {
    const query = this.query?.toLowerCase();
    if (query) {
      this.copyStores = this.stores.filter(r => (uiLabel(this.tr.getActiveLang(), r.title) ?? '').toLowerCase().includes(query)
        || (uiLabel(this.tr.getActiveLang(), r.city?.title) ?? '').toLowerCase().includes(query));
    } else {
      this.copyStores = this.stores;
    }
  }

  private checkNetwork() {
    this.headerService.network().subscribe(result => {
      this.isOnline = result === 'online';
      if (result === 'online') {
        this.toastr.success(this.tr.translate(`network.online`));
      } else {
        this.toastr.warning(this.tr.translate(`network.offline`));
      }
    });
  }

  private setLs(str: string): void {
    localStorage.setItem('sidebar', str);
  }
}
