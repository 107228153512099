<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title">
        {{ 'order-delivery.title' | transloco }}
      </h3>
    </div>
    <div>
      <button (click)="printOrders()"
              type="button"
              class="btn btn-outline-info m-btn m-btn--icon m--margin-right-10"
              [title]="'order-delivery.printOrder' | transloco">
        <span>
          <i class="la la-archive"></i>
          <span>{{ 'order-delivery.print' | transloco }}</span>
        </span>
      </button>
      <button (click)="packOrders()"
              [disabled]="isLoading.pack"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.pack}"
              type="button"
              class="btn btn-outline-warning m-btn m-btn--icon" [title]="'order-delivery.packOrders' | transloco">
        <span>
          <i class="la la-archive"></i>
          <span>{{ 'order-delivery.wrap' | transloco }}</span>
        </span>
      </button>
    </div>
  </div>
</div>

<div class="m-content">
  <!--Begin::Main Portlet-->
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="row m--padding-bottom-15">
            <div class="col-md-5">
              <ng-select bindLabel="itemName" bindValue="id" [multiple]="true" [placeholder]="'order-delivery.chooseStatus' | transloco"
                         [(ngModel)]="actions" (change)="onActionsChanged()">
                <ng-option [value]="'CREATED'">{{'enum.OrderAction.CREATED' | transloco}}</ng-option>
                <ng-option [value]="'PACKED'">{{'enum.OrderAction.PACKED' | transloco}}</ng-option>
                <ng-option [value]="'DECORED'">{{'enum.OrderAction.DECORED' | transloco}}</ng-option>
                <ng-option [value]="'SHIPPED'">{{'enum.OrderAction.SHIPPED' | transloco}}</ng-option>
                <ng-option [value]="'RECEIVED'">{{'enum.OrderAction.RECEIVED' | transloco}}</ng-option>
                <ng-option [value]="'CANCELLED'">{{'enum.OrderAction.CANCELLED' | transloco}}</ng-option>
                <ng-option [value]="'REFUNDED'">{{'enum.OrderAction.REFUNDED' | transloco}}</ng-option>
              </ng-select>
            </div>

            <div class="col-md-3">
              <ng-select [(ngModel)]="courierUserId" [items]="couriers" (change)="getTypeFilter()"
                         bindLabel="fullname" bindValue="id" [placeholder]="'order-delivery.chooseCourier' | transloco">
              </ng-select>
            </div>

            <div class="col-md-4">
              <form class="m-form" (ngSubmit)="getTypeFilter()">
                <div class="m-form__section m-form__section--first">
                  <div class="form-group m-form__group m--margin-top-0">
                    <div class="input-group">
                      <input type="text" required [(ngModel)]="query" name="searchText" class="form-control m-input" [placeholder]="'order-delivery.searchNumber' | transloco">
                      <span class="input-group-btn">
                        <button class="btn btn-outline-primary" type="submit">
                          {{ 'buttons.search' | transloco }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="row" style="align-items: center;">
            <div class="col-md-3">
              <hx-date-select [(ngModel)]="date" (selectChange)="getTypeFilter()"></hx-date-select>
            </div>

            <div class="col-md-3">
              <label class="m-checkbox m-checkbox--text" (change)="checkAll()">
                <input type="checkbox" [(ngModel)]="isAll">
                {{ 'order-delivery.chooseAll' | transloco }}
                <span></span>
              </label>
            </div>
            <div class="col-md-3 text-right">
              {{ 'order-delivery.choose' | transloco }}
            </div>
            <div class="col-md-1 m--padding-0">
              <ng-select [(ngModel)]="limit" [items]="limits" (ngModelChange)="getTypeFilter()"></ng-select>
            </div>

            <div class="col-md-2 text-right">
              <a (click)="resetFilter()" class="btn btn-outline-primary m-btn m-btn--icon" [title]="'order-delivery.refresh' | transloco">
                <span>{{ 'invoice-list.reset' | transloco }}</span>
              </a>
            </div>
          </div>

          <div class="m--margin-top-20" *ngIf="deliveryBuckets.length && !isLoading.list">
            <div *ngFor="let bucket of deliveryBuckets">
              <h4>
                ({{ bucket.deliveryRange.fromTime | time }} - {{ bucket.deliveryRange.toTime | time  }})
              </h4>

              <div class="m_datatable m-datatable--va-top m-datatable m-datatable--default m-datatable--loaded m--margin-top-15 m--margin-bottom-30">
                <table class="m-datatable__table" *ngIf="bucket.orders && bucket.orders.length">
                  <thead class="m-datatable__head">
                  <tr class="m-datatable__row m-datatable__row--head">
                    <th class="m-datatable__cell">
                      <span></span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.number' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.status' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.address' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.order' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell text-right">
                      <span>{{ 'order-delivery.sum' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.registration' | transloco }}</span>
                    </th>
                    <th class="m-datatable__cell">
                      <span>{{ 'order-delivery.client' | transloco }}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="m-datatable__body">
                  <tr *ngFor="let even = even; let i = index; let order of bucket.orders" class="m-datatable__row"
                      [ngClass]="{ 'm-datatable__row--even': even }">
                    <td class="m-datatable__cell">
                      <label class="m-checkbox" (change)="toggleOrder(order)">
                        <input type="checkbox" [name]="'checkbox_' + order.id" [checked]="order.selected">
                        <span></span>
                      </label>
                    </td>
                    <td class="m-datatable__cell" style="width: 90px;">
                      <a [routerLink]="'/orders/' + order.id">
                        {{ order.uniqueNumber }}
                      </a>
                    </td>
                    <td class="m-datatable__cell text-nowrap">
                      <span class="delivery">
                        <i class="la la-paperclip" [ngClass]="{invisible: !order.fileExists}"></i>
                        <i class="la la-birthday-cake" [ngClass]="{invisible: !order.decorExists}"></i>
                        <i class="la la-comment" [ngClass]="{invisible: !order.note}" [ngbTooltip]="order.note"></i>
                      </span>

                      <span class="m-badge  m-badge--{{ actionList.get(order.action) ?? 'default' }} m-badge--wide m-badge--rounded">
                        {{ 'action.' + order.action | transloco }} <i *ngIf="order.action === 'CREATED' && order.lastModifyDate" [ngbTooltip]="'order-delivery.refresh' | transloco: {date: order.lastModifyDate | date: 'dd.MM.yyyy HH:mm'}" class="la la-flag" style="font-size: 1rem;"></i>
                      </span>
                    </td>
                    <td class="m-datatable__cell" style="max-width: 250px;">
                      <ng-container *ngIf="order.address">
                        {{ order.address.address }} <ng-container *ngIf="order.address.admDiv">({{order.address.admDiv}})</ng-container>, <span *ngIf="order.address.entrance" [title]="'order-delivery.entrance' | transloco">{{ 'order-delivery.p' | transloco }}. {{ order.address.entrance }},</span>
                        <span *ngIf="order.address.floor">{{ 'order-delivery.floor' | transloco }} {{ order.address.floor }},</span>
                        <span *ngIf="order.address.flat">{{ 'order-delivery.flat' | transloco }}. {{ order.address.flat }}</span>
                      </ng-container>
                    </td>
                    <td class="m-datatable__cell">
                      <div *ngFor="let cake of order.products">
                        <div *ngIf="order.action === 'CANCELLED' || cake.status !== 'CANCELLED'" [ngClass]="{'is--red': cake.status === 'CANCELLED'}">
                          {{ cake.title | uiLabel }} <span *ngIf="cake.price.amount !== 1">{{cake.price.amount}}</span>
                          <span *ngIf="cake.type === 'PRODUCT'">{{ cake.weight | weight }} ×</span> {{ cake.amount }} {{ 'order-delivery.num' | transloco }}
                        </div>
                      </div>
                    </td>
                    <td class="m-datatable__cell text-right" [ngClass]="{'is--red': order.action === 'CANCELLED'}">
                      <ng-container *ngIf="order.payment">
                        <div *ngFor="let pt of order.payment.payments">
                          <i [hxPaymentIcon]="pt.type" [ngbTooltip]="'paymentType.' + pt.type | transloco"></i>
                          {{'paymentType.' + pt.type | transloco}}
                        </div>
                      </ng-container>

                      <span *ngIf="order.total" class="text-nowrap">
                          {{ order.total | uiCurrency: order.currency }}
                        </span>
                    </td>
                    <td class="m-datatable__cell">
                      {{ order.decorText }}
                    </td>
                    <td class="m-datatable__cell">
                      {{ order.phone }}<br>
                      {{ order.clientFullname }} <span *ngIf="order.clientType === 'LEGAL'" class="legal-client">{{ 'order-delivery.documents' | transloco }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <hx-loader [isLoading]="isLoading.list" [length]="deliveryBuckets.length"></hx-loader>

          <div class="m--align-center" *ngIf="deliveryBuckets.length && !isLoading.list && pagination.allItemCount > 0">
            <ngb-pagination [maxSize]="5" [rotate]="true" [collectionSize]="pagination.allItemCount"
                            [(page)]="pagination.currentPage" [pageSize]="pagination.limit" (pageChange)="pageChanged($event)" class="d-flex justify-content-center mt-4">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--End::Main Portlet-->
</div>
<hx-app-order-check></hx-app-order-check>
