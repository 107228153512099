<div class="m-form">
  <div class="m-form__section m-form__section--first">
    <div class="form-group m-form__group m--margin-top-0">
      <div *ngIf="onlyView" class="m--margin-top-5">
        {{ query }}
      </div>

      <div *ngIf="!onlyView" [ngClass]="{'is--loading': isLoading}">
        <div class="ui-select-container dropdown" [ngClass]="{'open': query && query.length > 1 && focused}">

          <input autocomplete="false" class="form-control ui-select-search" (keyup)="typed()" #currentItem [(ngModel)]="query" (focusin)="showOpen()" (focusout)="clear()" type="text" [placeholder]="'store-products.searchProduct' | transloco">

          <a (click)="removed()" *ngIf="!isLoading && query && query.length > 1" class="is--remove">
            <i class="la la-remove"></i>
          </a>

          <ul class="ui-select-choices dropdown-menu" role="menu" style="width: 100%;" *ngIf="!isLoading && !selectedItem">
            <ng-container *ngIf="items.length">
              <li role="menuitem" *ngFor="let item of items">
                <div class="ui-select-choices-row" style="padding-top: 3px; padding-bottom: 3px;">
                  <a class="dropdown-item align-content-center d-flex dropdown-item justify-content-between" (click)="selectItem(item)">
                    <div style="line-height: normal;">{{ item.productInfo.title | uiLabel }}</div>
                    <div *ngIf="item.unitOfMeasure">
                      <span style="color: #777; font-size: .9rem;" class="pull-right">{{ item.unitOfMeasure.localShortTitle | uiLabel }}</span>
                    </div>
                  </a>
                </div>
              </li>
            </ng-container>
            <!-- not found or empty -->
            <ng-container *ngIf="!items.length">
              <li role="menuitem">
                <div class="ui-select-choices-row">
                  <a class="dropdown-item">
                    <div>{{ 'store-products.notFound' | transloco }}</div>
                  </a>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
