<hx-order-info [order]="order" (orderChange)="onOrderChanged($event)">
  <ng-container *ngIf="!isArchive && order.status !== 'COMPLETED'">
    <!-- продажа заказа -->
    <button *ngIf="canSellOrder" (click)="showCashboxModal('sold')" class="btn btn-outline-success m-btn m-btn--icon m--margin-right-5"
            [ngbTooltip]="'order-view.btn.sell.tooltip' | transloco">
      <span>
        <i class="la la-shopping-cart"></i>
        <span>{{ 'buttons.sell' | transloco }}</span>
      </span>
    </button>

    <!-- отмена заказа не проданного -->
    <button *ngIf="canCancelOrder" [ngbTooltip]="tooltip.button.showCancelOrderModal | transloco"
            (click)="showCancelOrderModal()" class="btn btn-outline-danger m-btn m-btn--icon m--margin-right-5">
      <span>
        <i class="la la-remove"></i>
        <span *ngIf="isReceived">{{ 'buttons.return' | transloco }}</span>
        <span *ngIf="!isReceived">{{ 'buttons.cancel' | transloco }}</span>
      </span>
    </button>

    <!-- отмена заказа после продажи -->
    <button *ngIf="canCancelOrderProduct" [ngbTooltip]="tooltip.button.showCancelOrderProductModal | transloco"
            (click)="showCancelOrderProductModal()" class="btn btn-outline-danger m-btn m-btn--icon">
      <span>
        <i class="la la-remove"></i>
        <span *ngIf="order.paid">{{ 'buttons.return' | transloco }}</span>
        <span *ngIf="!order.paid">{{ 'buttons.cancel' | transloco }}</span>
      </span>
    </button>

    <!-- принятие предоплаты -->
    <button *ngIf="canAcceptPayment" (click)="showCashboxModal('paid')" class="btn btn-outline-success m-btn m-btn--icon"
            [ngbTooltip]="'order-view.btn.pay.tooltip' | transloco">
      <span>
        <i class="la la-cart-arrow-down"></i>
        <span>{{ 'buttons.pay' | transloco }}</span>
      </span>
    </button>
  </ng-container>
</hx-order-info>
