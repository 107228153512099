import { ResolveFn, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { MoneyReportComponent } from './pages/cash/money-report/money-report.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { OrderViewComponent } from './pages/order-view/order-view.component';
import { OrderDeliveryComponent } from './pages/order-delivery/order-delivery.component';
import { OrderCashboxComponent } from './pages/order-cashbox/order-cashbox.component';
import { UserOrderComponent } from './pages/user-order/user-order.component';
import { FiscalizationComponent } from '@cashbox-app/pages/fiscalization/fiscalization.component';
import { inject, NgModule } from '@angular/core';
import { HxOrderService, OrderResponse } from 'hx-services';
import { HxOrderModule } from 'hx-order';

const orderResolveFn: ResolveFn<OrderResponse> = route => inject(HxOrderService)
  .getFullOrderById(Number(route.paramMap.get('id')));

export const ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'sale'
      },
      {
        path: 'orders',
        loadChildren: () => import('hx-order').then(m => m.HxOrderModule),
      },
      {
        path: 'orders/:id',
        component: OrderViewComponent,
        pathMatch: 'full',
        resolve: {
          order: orderResolveFn,
        }
      },
      {
        path: 'delivery',
        component: OrderDeliveryComponent
      },
      {
        path: 'sale',
        component: OrderCashboxComponent
      },
      {
        path: 'invoices',
        component: InvoiceListComponent
      },
      {
        path: 'money-report',
        component: MoneyReportComponent,
        pathMatch: 'full'
      },
      {
        path: 'money-report/full',
        component: UserOrderComponent,
        pathMatch: 'full'
      },
      {
        path: 'options',
        component: SettingsComponent,
      },
      {
        path: 'fiscalization',
        component: FiscalizationComponent,
      },
      {
        path: 'faq',
        loadChildren: () => import('hx-component').then(m => m.HxFaqListModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'sale'
  }
];

@NgModule({
  imports: [
    HxOrderModule,
    RouterModule.forRoot(ROUTES, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'disabled',
    })
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
