<div [hidden]="curbsidePickups.length === 0" class="curbside-container">
  <i *ngIf="bottom" (click)="moveUp()" class="la la-angle-double-up"></i>
  <div class="curbside-body">
    <h5>{{'curbsidePickup.title' | transloco}}</h5>
    <p *ngFor="let cp of curbsidePickups">
      <button *ngIf="!cp.cbSeenDate" (click)="cashierSeen(cp)" class="btn btn-sm btn-outline-primary m--margin-right-5">{{'curbsidePickup.preparing' | transloco}}</button>
      <app-since [date]="cp.clientSendDate"></app-since> | {{cp.clientPhone}}
      <a [routerLink]="'/orders/' + cp.orderId">{{'curbsidePickup.viewOrder' | transloco}}</a>
      {{cp.comment}}
    </p>

    <div class="curbside-footer">
      <button *ngIf="isPlaying" class="btn btn-outline-success btn-sm" (click)="stopPlaying()">
        <i class="la la-bell-slash"></i>
        {{'curbsidePickup.stopPlaySound' | transloco}}
      </button>
    </div>
  </div>

  <i *ngIf="!bottom" (click)="moveDown()" class="la la-angle-double-down"></i>
</div>
