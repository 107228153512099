<div class="m-widget1 m--padding-0">
  <div class="m-widget1__item row" *ngIf="products.length && !isLoading.list">
    <ng-container *ngFor="let item of products">
      <ng-container *ngFor="let price of item.prices">
        <div class="col col-6">
          <h3 class="m-widget1__title m-widget1__title--list m--margin-bottom-10" (click)="addToCart(item, price)" [title]="item.title">
            <div class="m-widget1__line">
              <div>
                {{ item.title | uiLabel }}
                <span *ngIf="price.amount !== 1">{{ price.amount }}</span>
              </div>
              <div>
                {{ price.value.toLocaleString() }} {{currencySymbol}}
              </div>
            </div>
          </h3>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="m-widget1__item" *ngIf="isLoading.list">
    <i class="la la-spinner la-spin"></i> {{ 'kind-list.loader' | transloco }}
  </div>
</div>
