import { Component, OnDestroy, OnInit } from '@angular/core';
import { BalanceService } from '@cashbox-app/service/balance.service';
import { Subject } from 'rxjs';
import { HxAuthService, HxCategoryService, HxStoreProductService, ProductInfoType, StoreBasicModel, StoreProductModel } from 'hx-services';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';

@Component({
  selector: 'app-balance-view',
  templateUrl: './balance-view.component.html',
  styleUrls: ['./balance-view.component.css']
})
export class BalanceViewComponent implements OnInit, OnDestroy {
  products: StoreProductModel[] = [];
  isLoading = {
    storeBalance: false,
    category: false,
  };
  categoryIds: number[] = [];
  productInfoTitle?: string;
  productInfoTypes = [
    {id: 'storage', label: 'label.storage', types: [ProductInfoType.PRODUCT, ProductInfoType.INGREDIENT, ProductInfoType.PROPERTY]},
    {id: 'service', label: 'label.service', types: [ProductInfoType.SERVICE]}
  ];
  selectedProductInfoTypes?: ProductInfoType[];
  productInfoTypeId = 'storage';
  store!: StoreBasicModel;
  private $destroyed = new Subject<void>();

  constructor(
    private balanceService: BalanceService,
    private storeProductService: HxStoreProductService,
    private errorService: ErrorHandlerService,
    private categoryService: HxCategoryService,
    private auth: HxAuthService,
  ) {
  }

  ngOnInit() {
    this.balanceService.updateBalanceObs.pipe(takeUntil(this.$destroyed)).subscribe(() => this.loadBalance());
    this.store = this.auth.user.store;
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  searchProduct() {
    if (!this.productInfoTitle) {
      return;
    }
    this.loadBalance();
  }

  selectProductInfoType(id: string, types: ProductInfoType[]) {
    this.selectedProductInfoTypes = types;
    this.productInfoTypeId = id;
    this.productInfoTitle = undefined;
    if (id !== 'storage') {
      this.categoryIds = [];
    }
    this.loadBalance();
  }

  resetFilters() {
    this.categoryIds = [];
    this.productInfoTitle = undefined;
    this.loadBalance();
  }

  onProductCategoryChanged() {
    this.loadBalance();
  }

  private loadBalance() {
    this.isLoading.storeBalance = true;
    this.products = [];
    if (this.productInfoTypeId !== 'storage' || this.categoryIds.length) {
      this.storeProductService.getBalance({
        storeId: this.store.id,
        productInfoTitle: this.productInfoTitle,
        categoryIds: this.categoryIds,
        productInfoTypes: this.selectedProductInfoTypes
      }).subscribe(result => {
        this.products = result;
        this.isLoading.storeBalance = false;
      }, err => {
        this.isLoading.storeBalance = false;
        this.errorService.handle(err);
      });
    } else {
      this.isLoading.storeBalance = false;
    }
  }
}
