import { Component, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import {
  CalculateDiscountRequest,
  CartProduct,
  Currency,
  DeliveryType,
  DictionaryEntryModel,
  DiscountResultModel,
  Entity,
  getCurrencySymbol,
  getPaymentValue,
  HxAuthService,
  HxCoinService,
  HxOrderService,
  HxStoreService,
  isoDate,
  NotifierEventBusAddress,
  NotifierService,
  OrderResponse,
  PaymentHolder,
  PaymentType,
  populatePaymentHolder,
  ProductShortModel,
  ProductStatus,
  resetPaymentHolder,
  SalePriceModel,
  SaveOrderProductsRequest,
  SoldOrderRequest,
  StoreBasicModel,
  StorePaymentType,
  toFixed,
  toPaymentArray
} from 'hx-services';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { TranslocoService } from '@ngneat/transloco';
import { format, parseISO } from 'date-fns';
import { debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { OrderCancelModal } from '@cashbox-app/modal/order-cancel/order-cancel.modal';

export interface CashboxOperationResult {
  print: boolean;
  success: boolean;
  operation: string;
}

interface SoldOrder extends PaymentHolder {
  uniqueNumber: string;
  paid: boolean;
  id: number;
  total: number;
  subTotal: number;
  storeId: number;
  date: string;
  storePaymentType?: StorePaymentType;
  clientId?: number;
  clientType?: Entity;
  checkingAccountFileId?: number;
  discounts: DiscountResultModel[];
  products: ProductShortModel[];
}

interface SoldCartProduct extends CartProduct {
  initialReservedAmount?: number;
  weight?: number;
  beforeAmount?: number;
  afterAmount?: number;
  refunded?: ProductShortModel;
  value?: number;
  discounts?: DiscountResultModel[];
}

/**
 * Component sold order - button with modal
 */
@Component({
  selector: 'app-cashbox-modal',
  templateUrl: './cashbox-operation.modal.html',
  styleUrls: ['./cashbox-operation.modal.css']
})
export class CashboxOperationModal implements OnDestroy, OnInit {
  @Input() orderId!: number;
  @Input() operation!: 'paid' | 'sold' | 'sold-paid';
  order?: SoldOrder;
  childOrders: OrderResponse[] = [];
  isSoldOperation = false;
  isPaidOperation = false;
  isSoldPaidOperation = false;
  isLoading = {
    sold: false,
    discounts: false,
    order: false,
    modifyAmount: false,
    paid: false,
    coins: false,
  };
  payType?: PaymentType;  // хранит выбранные способ оплаты
  paymentTypes: PaymentType[] = []; // доступные виды оплаты у организации
  paymentType = PaymentType;
  currency!: Currency;
  currencySymbol!: string;
  isExternalPay = false;      // true if kaspi/rahmet/senim
  isPrintCheck = true;        // печатать чек?
  isMixedPayment = false;     // true когда выбраны POS & CASH
  applyCoins = false;
  selectedPaymentType: PaymentType = PaymentType.CASH;
  // cart related fields
  hasUnsavedProducts = false; // есть не сохраненные продукты
  cartProducts: SoldCartProduct[] = [];
  orderDate?: string;
  soldRequest?: SoldOrderRequest;
  isProductList = false;      // отобразить продукты для добавления?
  totalItem = 0;
  total = 0;
  discounts: DiscountResultModel[] = [];
  orderModel?: OrderResponse;
  orderStore?: StoreBasicModel;
  showOrderPaidMessage = false;
  isReceivedDocs = false;
  referrers: DictionaryEntryModel[] = [];
  otherReferrers: DictionaryEntryModel[] = [];
  otherReferrersVisible = false;
  referrerEntry?: DictionaryEntryModel;
  coinEarnAmount = 0;
  coinAmount = 0;
  paymentRemainder = 0;
  private $destroyed = new Subject<void>();
  private $calcSubject = new Subject<void>();

  constructor(
    private orderService: HxOrderService,
    private cashboxService: CashboxService,
    private modal: NgbModal,
    private toastr: ToastrService,
    private errorHandler: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
    private storeService: HxStoreService,
    private fiscalizationService: FiscalizationService,
    private tr: TranslocoService,
    private auth: HxAuthService,
    private coinService: HxCoinService,
    private notifierService: NotifierService,
  ) {
  }

  ngOnInit() {
    this.currency = this.auth.user.store.currency;
    this.currencySymbol = getCurrencySymbol(this.currency);
    this.isPrintCheck = !CashboxService.isKktPrint();
    this.isSoldOperation = this.operation === 'sold';
    this.isPaidOperation = this.operation === 'paid';
    this.isSoldPaidOperation = this.operation === 'sold-paid';
    this.loadOrder();

    this.orderService.updateOrderObs.pipe(takeUntil(this.$destroyed))
      .subscribe(() => this.loadOrder());

    this.orderService.recalcDiscountsObs.pipe(takeUntil(this.$destroyed))
      .subscribe(() => this.recalculateTotal());

    this.notifierService.eventBusOpenObs.pipe(takeUntil(this.$destroyed)).subscribe(eb => {
      if (eb) {
        this.notifierService.registerHandler(NotifierEventBusAddress.orderEvent(this.orderId), this.orderEventHandler);
      }
    });

    this.$calcSubject.pipe(
      takeUntil(this.$destroyed),
      debounceTime(400),
      tap(() => this.isLoading.discounts = true)
    ).subscribe(() => this.doRecalcDiscounts());
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  loadActivePaymentTypes(storeId: number): void {
    this.storeService.getActivePaymentTypes(storeId)
      .subscribe(paymentTypes => this.paymentTypes = paymentTypes,
        err => this.errorHandler.handle(err));
  }

  recalculateTotal(): void {
    this.$calcSubject.next();
  }

  toggleDiscount(discount: DiscountResultModel): void {
    if (this.order?.paid) {
      return;
    }
    discount.enabled = !discount.enabled;
    this.recalculateTotal();
  }

  async sold() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    const soldRequest = this.getSoldRequest(this.order);
    const debtValue = getPaymentValue(soldRequest.payments, PaymentType.DEBT);
    if (!this.isSumEqualTotal() && debtValue === 0) {
      this.toastr.warning(this.tr.translate('cashbox-operation.checkAmount'));
      return;
    }
    if (debtValue > 0) {
      soldRequest.payments = soldRequest.payments.filter(p => p.type === PaymentType.DEBT);
    }

    this.isLoading.sold = true;
    try {
      await this.cashboxService.soldOrder(this.order.id, soldRequest);
      this.closeModal();
    } catch (err: any) {
      this.errorHandler.handle(err);
    } finally {
      this.isLoading.sold = false;
    }
  }

  async paid() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    this.isLoading.paid = true;
    try {
      await this.cashboxService.paidOrder(this.order.id, {
        total: this.order.total,
        payments: toPaymentArray(this.order),
        discounts: this.discounts,
        referrer: this.referrerEntry?.code,
      });
      this.closeModal();
    } catch (err: any) {
      this.errorHandler.handle(err);
    } finally {
      this.isLoading.paid = false;
    }
  }

  async soldPaid() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if (!this.isSumEqualTotal() && !this.order.debt) {
      this.toastr.warning(this.tr.translate('checkTotalSum'));
      return;
    }

    this.order.cash = this.order.debt ? 0 : this.order.cash;
    this.order.pos = this.order.debt ? 0 : this.order.pos;
    this.isLoading.sold = true;
    try {
      await this.cashboxService.soldPaidOrder(this.order.id, {
        total: this.order.total,
        payments: toPaymentArray(this.order),
        discounts: this.discounts,
        referrer: this.referrerEntry?.code,
      });
      this.closeModal();
    } catch (err: any) {
      this.errorHandler.handle(err);
      if (err.error.message === 'order.alreadyPaid') {
        this.rerenderButtons();
      }
    } finally {
      this.isLoading.sold = false;
    }
  }

  async showCancelOrderModal() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    const cancelModal = this.modal.open(OrderCancelModal, {size: 'lg'});
    cancelModal.componentInstance.orderId = this.order.id;
    const cancelled = await cancelModal.result;
    if (cancelled) {
      this.modalInstance.dismiss();
      this.toastr.success(this.tr.translate('cashbox-operation.successfullyCanceled'));
    }
  }

  radioChange(paymentType: PaymentType) {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    this.selectedPaymentType = paymentType;
    if (![PaymentType.CASH, PaymentType.POS, PaymentType.KASPI_POS, PaymentType.COIN].includes(paymentType)) {
      this.isMixedPayment = false;
    }
    if ([PaymentType.CASH, PaymentType.POS, PaymentType.CHECKING_ACCOUNT, PaymentType.KASPI_POS].includes(paymentType)) {
      this.resetPayment();
      populatePaymentHolder(this.order, [{type: paymentType, value: this.order.total}]);
    }
    this.onApplyCoinsChanged();
  }

  onCashChanged() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    // this.order.pos = toFixed(this.order.total - (this.applyCoins ? (this.clientCoins ?? 0) : 0) - (this.order.cash ?? 0));
    this.paymentRemainder = toFixed(this.order.total - (this.applyCoins ? this.coinAmount : 0) - (this.order.pos ?? 0) - (this.order.kaspiPos ?? 0) - (this.order.cash ?? 0));
  }

  onPosChanged() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    // this.order.cash = toFixed(this.order.total - (this.applyCoins ? (this.clientCoins ?? 0) : 0) - (this.order.pos ?? 0));
    this.paymentRemainder = toFixed(this.order.total - (this.applyCoins ? this.coinAmount : 0) - (this.order.pos ?? 0) - (this.order.kaspiPos ?? 0) - (this.order.cash ?? 0));
  }

  onKaspiPosChanged() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    // this.order.kaspiPos = toFixed(this.order.total - (this.applyCoins ? (this.clientCoins ?? 0) : 0) - (this.order.pos ?? 0));
    this.paymentRemainder = toFixed(this.order.total - (this.applyCoins ? this.coinAmount : 0) - (this.order.pos ?? 0) - (this.order.kaspiPos ?? 0) - (this.order.cash ?? 0));
  }

  onMixedPaymentChanged() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if (!this.isMixedPayment) {
      if (this.selectedPaymentType === PaymentType.CASH) {
        this.order.cash = this.order.total;
        this.order.pos = 0.0;
      } else if (this.selectedPaymentType === PaymentType.POS) {
        this.order.pos = this.order.total;
        this.order.cash = 0.0;
      }
    }
    this.onApplyCoinsChanged();
  }

  toggleProductList() {
    this.isProductList = !this.isProductList;
    if (!this.orderStore && this.orderModel) {
      this.storeService.getStoreById(this.orderModel.storeId).then(store => this.orderStore = store);
    }
  }

  onProductAdded(event: { price: SalePriceModel }) {
    this.addProduct(event.price);
  }

  setPaymentType(type: PaymentType): void {
    this.resetPayment();
    this.payType = type;
    this.isExternalPay = [PaymentType.KASPI].includes(type);
    if (this.isExternalPay && this.order) {
      this.soldRequest = this.getSoldRequest(this.order);
    }
  }

  resetPaymentType() {
    this.resetPayment();
    this.payType = undefined;
    this.isExternalPay = false;
  }

  async onOrderPaid() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    this.order.paid = true;
    this.showOrderPaidMessage = true;
    this.isExternalPay = false;
    this.resetPayment();
    // необходимо переподтягивать способ оплаты - так как могут оплатить другим способом
    const order = await this.orderService.getOrderById(this.order.id);
    populatePaymentHolder(this.order, order.payment.payments);
  }

  dismissModal(reason?: string) {
    this.modalInstance.dismiss(reason);
  }

  async addProduct(price: SalePriceModel) {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if (this.order.paid) {
      const product = this.cartProducts.find(cp => cp.price.id === price.id);
      if (!product) {
        return;
      }
      if ((product.reserved?.amount ?? 0) - Math.abs(product.refunded?.amount ?? 0) === ((product.initialReservedAmount ?? 0) - Math.abs(product.refunded?.amount ?? 0))) {
        this.toastr.warning(this.tr.translate('cashbox-operation.productsPaid'));
        return;
      }
    }
    if (this.isLoading.modifyAmount) {
      return;
    }
    this.isLoading.modifyAmount = true;
    const cartProduct = this.cartProducts.find(pr => pr.price.id === price.id);
    if (cartProduct) {
      const reservedAmount = cartProduct.reserved?.amount ?? 0;
      const refundedAmount = Math.abs(cartProduct.refunded?.amount ?? 0);
      if (cartProduct.reserved && reservedAmount >= 0 && reservedAmount < ((cartProduct.initialReservedAmount ?? 0) - refundedAmount)) {
        cartProduct.reserved.amount += 1;
        cartProduct.reserved.value += cartProduct.price.value;
        this.recalculateTotal();
        this.isLoading.modifyAmount = false;
      } else {
        try {
          const savedProduct = await this.orderService.addCartProduct(this.order.id, {priceId: price.id});
          cartProduct.cart = savedProduct;
          this.recalculateTotal();
        } finally {
          this.isLoading.modifyAmount = false;
        }
      }
    } else {
      try {
        const savedProduct = await this.orderService.addCartProduct(this.order.id, {priceId: price.id});
        this.cartProducts.push({
          price: savedProduct.price,
          productInfo: savedProduct.productInfo,
          cart: savedProduct
        } as CartProduct);
        this.recalculateTotal();
      } finally {
        this.isLoading.modifyAmount = false;
      }
    }
  }

  subtractProductAmount(product: SoldCartProduct): void {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if (product.cart) {
      this.isLoading.modifyAmount = true;
      this.orderService.downCartProduct(this.order.id, product.cart.id)
        .pipe(finalize(() => this.isLoading.modifyAmount = false))
        .subscribe(() => {
          if (product.cart) {
            if (product.cart.amount === 1) {
              if (product.reserved || product.refunded) {
                product.cart = undefined;
              } else {
                this.cartProducts = this.cartProducts.filter(pr => pr.price.id !== product.price.id);
              }
            } else {
              product.cart.amount -= 1;
              product.cart.value -= product.price.value;
            }
            this.recalculateTotal();
          }
        }, err => this.errorHandler.handle(err));
    } else if (product.reserved) {
      if (product.reserved.amount > 0) {
        product.reserved.amount -= 1;
        product.reserved.value -= product.price.value;
      }
      this.recalculateTotal();
    }
  }

  trackByFn: TrackByFunction<SoldCartProduct> = (index, obj) => obj.price.id;

  async saveOrderWithProducts() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    this.isLoading.discounts = true;
    const products = this.cartProducts.map(p => ({
      amount: (p.reserved?.amount ?? 0) + (p.cart?.amount ?? 0),
      priceAmount: p.price.amount,
      productDefinitionId: p.productInfo.id,
    }));
    const request: SaveOrderProductsRequest = {
      total: this.order.total,
      subTotal: this.order.subTotal,
      payments: [],
      products: products,
      discounts: this.discounts,
    };
    if (this.order.paid) {
      request.payments = toPaymentArray(this.order);
      if (!this.isMixedPayment) {
        const payment = request.payments.find(pt => pt.value > 0);
        if (payment) {
          payment.value = this.order.total;
        }
      }
    }
    try {
      const {refundOrderId} = await this.cashboxService.saveProducts(this.order.id, request);
      if (refundOrderId) {
        this.fiscalizationService.fiscalizeIfOffline(refundOrderId).then(isFiscalized => {
          if (isFiscalized) {
            this.toastr.info(this.tr.translate('cashbox-operation.refundFiscalized'));
          }
        });
      }

      await this.loadOrder();
      this.orderService.orderUpdated();
      this.closeIfSellNotAvailable();
    } catch (err: any) {
      const errorMessage = err.error?.message ?? 'unexpected';
      const errorId = err.error?.id;
      this.toastr.error(this.tr.translate('cashbox-operation.error', {errorMessage: errorMessage, errorId: errorId}));
      this.isLoading.discounts = false;
    }
  }

  setReferrer(item: DictionaryEntryModel) {
    if (this.referrerEntry?.code === item.code) {
      this.referrerEntry = undefined;
    } else {
      this.referrerEntry = item;
    }
  }

  async onApplyCoinsChanged() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    this.isLoading.coins = true;
    try {
      const [coinEarnAmount] = await Promise.all([
        this.coinService.calculateAppliedCoins(this.orderId, this.applyCoins ? this.coinAmount : 0),
        this.orderService.updateApplyCoins(this.orderId, this.applyCoins),
      ]);
      this.coinEarnAmount = coinEarnAmount;

      if (this.applyCoins && this.coinAmount) {
        if (this.coinAmount >= this.order.total) {
          this.resetPayment();
          this.order.coin = this.order.total;
        } else {
          if (this.selectedPaymentType === PaymentType.CASH && this.order.cash) {
            this.order.cash -= this.coinAmount;
          } else if (this.selectedPaymentType === PaymentType.POS && this.order.pos) {
            this.order.pos -= this.coinAmount;
          } else if (this.selectedPaymentType === PaymentType.KASPI_POS && this.order.kaspiPos) {
            this.order.kaspiPos -= this.coinAmount;
          }
          this.order.coin = this.coinAmount;
        }
      } else {
        this.order.coin = 0;
        if (this.selectedPaymentType) {
          if ([PaymentType.CASH, PaymentType.POS, PaymentType.CHECKING_ACCOUNT, PaymentType.KASPI_POS].includes(this.selectedPaymentType)) {
            this.resetPayment();
            populatePaymentHolder(this.order, [{type: this.selectedPaymentType, value: this.order.total}]);
          }
        }
      }
    } finally {
      this.isLoading.coins = false;
    }
  }

  toggleMoreReferrers() {
    this.otherReferrersVisible = !this.otherReferrersVisible;
  }

  private initCartProducts(products: ProductShortModel[]) {
    const cartProducts: SoldCartProduct[] = [];
    products.forEach((product, index) => {
      let cartProduct = cartProducts.find(cp => cp.price.id === product.price.id);
      if (!cartProduct) {
        cartProduct = {
          price: product.price,
          productInfo: product.productInfo,
          beforeAmount: 0,
          afterAmount: 0
        } as SoldCartProduct;
        cartProducts.push(cartProduct);
      }

      if (ProductStatus.RESERVED === product.status) {
        cartProduct.reserved = product;
        cartProduct.initialReservedAmount = product.amount;
      } else if (ProductStatus.CART === product.status) {
        cartProduct.cart = product;
      }
    });

    cartProducts.forEach(cartProduct => cartProduct.refunded = undefined);
    this.childOrders.forEach(childOrder => {
      childOrder.products.forEach(refundedProduct => {
        const cartProduct = cartProducts.find(cp => cp.price.id === refundedProduct.price.id);
        if (cartProduct) {
          if (cartProduct.refunded) {
            cartProduct.refunded.value += refundedProduct.value;
            cartProduct.refunded.amount += refundedProduct.amount;
          } else {
            cartProduct.refunded = refundedProduct;
          }
        }
      });
    });

    cartProducts.filter(cartProduct => !!cartProduct.reserved).forEach(cartProduct => {
      const availableAmount = (cartProduct.initialReservedAmount ?? 0) - Math.abs(cartProduct.refunded?.amount ?? 0);
      if (cartProduct.reserved && cartProduct.reserved.amount > availableAmount) {
        const diffAmount = cartProduct.reserved.amount - availableAmount;
        cartProduct.reserved.amount -= diffAmount;
        cartProduct.reserved.value -= cartProduct.price.value * diffAmount;
      }
    });

    this.populateCartProducts(cartProducts);
  }

  private resetPayment() {
    resetPaymentHolder(this.order);
  }

  private getSoldRequest(order: SoldOrder): SoldOrderRequest {
    return {
      payments: toPaymentArray(order),
      total: order.total,
      subTotal: order.subTotal,
      discounts: this.discounts,
      referrer: this.referrerEntry?.code,
      products: [],
    };
  }

  private async updateSoldCash() {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if ((this.order.cash ?? 0) > 0 && this.order.pos === 0) {
      this.order.cash = this.order.total;
    } else if ((this.order.pos ?? 0) > 0 && this.order.cash === 0) {
      this.order.pos = this.order.total;
    } else if (this.order.paid) {
      if (this.order.cash === 0 && this.order.pos === 0) {
        const order = await this.orderService.getOrderById(this.order.id);
        if (order.total === this.order.total) {
          if (order.payment) {
            this.order.cash = getPaymentValue(order.payment?.payments, PaymentType.CASH);
            this.order.pos = getPaymentValue(order.payment?.payments, PaymentType.POS);
          } else {
            this.order.cash = 0;
            this.order.pos = 0;
          }
        }
      }
    }
    this.isMixedPayment = toPaymentArray(this.order).filter(p => p.value > 0).length > 1;
  }

  private populateCartProducts(cartProducts: SoldCartProduct[]) {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    cartProducts.forEach(cartProduct => {
      cartProduct.beforeAmount = (cartProduct.initialReservedAmount ?? 0) - Math.abs(cartProduct.refunded?.amount ?? 0);
      cartProduct.afterAmount = (cartProduct.reserved?.amount ?? 0) + (cartProduct.cart?.amount ?? 0);
      cartProduct.value = toFixed((cartProduct.reserved?.value ?? 0) + (cartProduct.cart?.value ?? 0));
      cartProduct.weight = (cartProduct.reserved ?? cartProduct.cart)?.weight;
      cartProduct.discounts = this.discounts.filter(dsc => dsc.priceId === cartProduct.price.id);
    });

    if (this.order.paid) {
      // remove products where beforeAmount = 0
      cartProducts = cartProducts.filter(cartProduct => (cartProduct.beforeAmount ?? 0) > 0);
    }

    const hasUnsavedProductsFn = () => {
      const cartProductCount = cartProducts.filter(cp => cp.cart).length;
      if (cartProductCount > 0) {
        return true;
      }
      return cartProducts.some(cp => cp.beforeAmount !== cp.afterAmount);
    };
    this.hasUnsavedProducts = hasUnsavedProductsFn();
    this.totalItem = cartProducts.map(el => (el.reserved?.amount ?? 0) + (el.cart?.amount ?? 0)).reduce((a, b) => a + b, 0);
    this.cartProducts = cartProducts;
    this.total = toFixed(this.order.total - Math.abs(this.childOrders.map(co => co.total).reduce((a, b) => a + b, 0)));
  }

  private async loadOrder() {
    this.isLoading.order = true;
    try {
      const [orderResponse, childOrders, discounts, tracking] = await Promise.all([
        this.orderService.getOrderCartById(this.orderId),
        this.orderService.getChildOrders(this.orderId),
        this.orderService.getDiscountList(this.orderId),
        this.orderService.getTracking(this.orderId),
      ]);
      this.storeService.getReferrersByStoreId(orderResponse.storeId).then(refs => {
        const allReferrers = refs.filter(ref => ref.enabled);
        if (allReferrers.length > 5) {
          this.referrers = allReferrers.slice(0, 5);
          this.otherReferrers = allReferrers.slice(5);
        } else {
          this.referrers = allReferrers;
        }
        if (tracking?.referrer) {
          this.referrerEntry = allReferrers.find(ref => ref.code === tracking.referrer);
        }
      });
      this.orderModel = orderResponse;
      this.applyCoins = orderResponse.applyCoins;
      const soldOrder: SoldOrder = {
        // checkingAccountFileId: this.order.chec,
        date: orderResponse.date,
        id: orderResponse.id,
        uniqueNumber: orderResponse.uniqueNumber,
        paid: orderResponse.paid,
        products: orderResponse.products, // Object.assign([], this.order.products),//{...this.order.products},
        storeId: orderResponse.storeId,
        storePaymentType: orderResponse.storePaymentType,
        subTotal: orderResponse.subTotal,
        total: orderResponse.total,
        clientId: orderResponse.clientId,
        clientType: orderResponse.clientType,
        discounts: discounts.results // Object.assign([], discounts) //{...discounts}
      };
      populatePaymentHolder(soldOrder, orderResponse.payment?.payments);
      this.order = soldOrder;
      this.childOrders = childOrders;
      this.isMixedPayment = toPaymentArray(this.order).filter(p => p.type !== PaymentType.COIN && p.value > 0).length > 1;
      if (childOrders) {
        this.order.cash = toFixed((this.order.cash ?? 0) - Math.abs(childOrders.map(co => getPaymentValue(co.payment.payments, PaymentType.CASH)).reduce((a, b) => a + b, 0)));
        this.order.pos = toFixed((this.order.pos ?? 0) - Math.abs(childOrders.map(co => getPaymentValue(co.payment.payments, PaymentType.POS)).reduce((a, b) => a + b, 0)));
        this.order.kaspiPos = toFixed((this.order.kaspiPos ?? 0) - Math.abs(childOrders.map(co => getPaymentValue(co.payment.payments, PaymentType.KASPI_POS)).reduce((a, b) => a + b, 0)));
        this.order.coin = toFixed((this.order.coin ?? 0) - Math.abs(childOrders.map(co => getPaymentValue(co.payment.payments, PaymentType.COIN)).reduce((a, b) => a + b, 0)));
      }
      this.discounts = this.order.discounts;
      this.orderDate = isoDate(this.order.date);
      this.loadActivePaymentTypes(this.order.storeId);
      this.initCartProducts(this.order.products);
      if (!this.order.paid) {
        this.order.cash = this.order.total;
      }
      this.recalculateTotal();

      if (orderResponse.clientId) {
        this.isLoading.coins = true;
        const {brandId, countryId} = this.auth.user.store;
        try {
          if (orderResponse.paymentDate) {
              this.coinAmount = soldOrder.coin ?? 0;
          } else {
              this.coinAmount = await this.coinService.getActiveCoins({clientId: orderResponse.clientId, countryId: countryId, brandId: brandId});
          }
            this.coinEarnAmount = await this.coinService.calculateAppliedCoins(this.orderId, this.applyCoins ? this.coinAmount : 0);
        } catch (err: any) {
          this.errorHandler.handle(err);
        } finally {
          this.isLoading.coins = false;
        }
      }
    } finally {
      this.isLoading.order = false;
    }
  }

  private closeIfSellNotAvailable() {
    if (this.order?.paid) {
      if (this.order.total === 0) {
        this.modalInstance.close();
      }
    }
  }

  private doRecalcDiscounts(): void {
    if (!this.order) {
      throw new Error('order.undefined');
    }
    if (this.order.paid && this.operation === 'sold') {
      this.isLoading.discounts = false;
      return;
    }
    const request: CalculateDiscountRequest = {
      results: this.discounts,
      order: {
        id: this.order.id,
        date: this.order.date.substring(0, 10),
        time: format(parseISO(this.order.date), 'HH:mm'),
        storeId: this.order.storeId,
        clientId: this.order.clientId,
        clientType: this.order.clientType ?? Entity.INDIVIDUAL,
        deliveryType: DeliveryType.PICKUP,
      },
      products: [],
    };

    request.products = this.cartProducts.map(item => ({
      amount: (item.reserved?.amount ?? 0) + (item.cart?.amount ?? 0),
      priceId: item.price.id
    }));

    this.orderService.checkDiscount(request)
      .pipe(finalize(() => this.isLoading.discounts = false))
      .subscribe(result => {
        this.discounts = result.results ?? [];
        if (this.order) {
          this.order.total = result.order.total;
          this.order.subTotal = result.order.subTotal;
        }
        this.populateCartProducts(this.cartProducts);
        this.updateSoldCash();
        if (!this.isMixedPayment) {
          this.radioChange(this.selectedPaymentType);
        }
      });
  }

  private isSumEqualTotal() {
    if (!this.order) {
      return false;
    }
    return this.order.total === toFixed(toPaymentArray(this.order).filter(p => p.value > 0).map(p => p.value).reduce((a, b) => a + b, 0));
  }

  private closeModal() {
    this.modalInstance.close({
      print: this.isPrintCheck,
      success: true,
      operation: this.operation
    } as CashboxOperationResult);
  }

  private orderEventHandler: (err: any, message: { body: any, headers: any }) => void = (err, message) => {
    if (err) {
      console.error(err);
    } else {
      console.log('message.headers.action=', message.headers.action);
      if (message.headers.action === 'orderPaid') {
        this.rerenderButtons();
      }
    }
  };

  private rerenderButtons() {
    this.operation = 'sold';
    this.isSoldOperation = true;
    this.isPaidOperation = false;
    this.isSoldPaidOperation = false;
    this.loadOrder();
  }
}
