<div *ngIf="!isFiscalPaperReady" class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--head-solid-bg">
        <div class="row">
          <div class="col-lg-8">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    {{ 'fiscalization.noPaperQuestion' | transloco }}?

                    <button type="button" (click)="confirmNoPaper()" [disabled]="isLoading.paper"
                            class="btn btn-success m--margin-left-30">
                      {{ 'fiscalization.yes' | transloco }}
                    </button>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="m-subheader">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <h3 class="m-subheader__title m-subheader__title--separator">
        {{ 'fiscalization.title' | transloco }}
      </h3>
      <ul class="m-subheader__breadcrumbs m-nav m-nav--inline" *ngIf="deviceStatus">
        <li class="m-nav__item">
          <span class="m-nav__link-text">
            {{'atol.shift.status.' + deviceStatus.shift | transloco}}
          </span>
        </li>
      </ul>
    </div>
    <div>
      <button (click)="openShift()" [disabled]="isLoading.openShift || deviceStatus?.shift === 'opened'" class="btn btn-outline-primary btn-sm"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.openShift}">
        {{ (isLoading.openShift ? 'order-product-cancel.shiftOpens' : 'order-product-cancel.shiftOpen') | transloco}}
      </button>
      <button (click)="closeShift()" [disabled]="isLoading.closeShift || deviceStatus?.shift !== 'opened' && deviceStatus?.shift !== 'expired'" class="btn btn-outline-primary btn-sm"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.closeShift}">
        {{ (isLoading.closeShift ? 'order-product-cancel.shiftCloses' : 'order-product-cancel.shiftClose') | transloco}}
      </button>
      <button (click)="fiscalizeAll()" [disabled]="isLoading.fiscalizeAll" class="btn btn-outline-primary btn-sm"
              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': isLoading.closeShift}">
        {{ 'order-product-cancel.fiscalizeAll' | transloco}}
      </button>
    </div>
  </div>
</div>

<div class="m-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--head-solid-bg">
        <div class="row">
          <div class="col-lg-8">
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    {{ 'fiscalization.title' | transloco }}
                  </h3>
                </div>
              </div>

              <!--<div class="m-portlet__head-tools">
                <ul class="m-portlet__nav">
                  <li class="m-portlet__nav-item">
                    <span class="m-badge  m-badge&#45;&#45;{{ statusList[order.action]?.badge }} m-badge&#45;&#45;wide m-badge&#45;&#45;rounded">
                      {{ 'action.' + order.action | transloco }}
                    </span>
                  </li>
                </ul>
              </div>-->
            </div>
          </div>

          <div class="col-lg-4">
            <div class="hc-source-header">
              <span>{{ 'fiscalization.modelKkt' | transloco }}: {{ deviceInfo?.modelName }} ({{ 'fiscalization.model' | transloco }} {{deviceInfo?.model}})</span>
            </div>
          </div>
        </div>

        <div class="m-portlet__body">
          <div class="row">
            <div class="col-lg-8">
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 100px;">{{ 'fiscalization.order' | transloco }}</th>
                  <th style="width: 135px;">{{ 'fiscalization.date' | transloco }}</th>
                  <th style="width: 70px;">{{ 'fiscalization.sum' | transloco }}</th>
                  <th style="width: 100px;">{{ 'fiscalization.type' | transloco }}</th>
                  <th>#</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let ord of nonFiscalizedOrders; trackBy: trackByFn">
                  <tr>
                    <td>
                      <i *ngIf="ord.fiscalled" class="la la-check-circle"></i>
                      <a [routerLink]="['/orders', ord.id]">{{ord.uniqueNumber}}</a>
                    </td>
                    <td>
                      {{ord.eventDate | date: 'dd.MM.yyyy HH:mm': timezone}}
                    </td>
                    <td>
                      {{ord.total}}
                    </td>
                    <td>
                      {{ (ord.total > 0 ? 'sidebar.sale' : 'buttons.return') | transloco }}
                    </td>
                    <td>
                      <button (click)="fiscalize(ord)" [disabled]="ord.isFiscalizing || (!isFiscalPaperReady && !deviceInfo)" class="btn btn-outline-primary btn-sm"
                              [ngClass]="{'m-loader m-loader--light m-loader--primary m-loader--left': ord.isFiscalizing}">
                        {{ (ord.isFiscalizing ? 'sidebar.fiscalization' : 'fiscalization.fiscalization') | transloco}}
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="ord.error">
                    <td colspan="5" class="fiscal-error">
                      <div class="alert alert-danger">{{ord.error.description}}</div>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
            </div>
            <div class="col-lg-4">
              <ng-container *ngIf="deviceInfo">
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.firmwareVersion' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.firmwareVersion">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.kktVersion' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.appVersion">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.downVersion' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.bootVersion">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.kktNumber' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.serial">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.ffdVersion' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.ffdVersion">
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.ffdFnVersion' | transloco }}
                  </label>
                  <div class="col-6">
                    <input disabled class="form-control m-input" type="text" [value]="deviceInfo.fnFfdVersion">
                  </div>
                </div>
              </ng-container>
              <hr>
              <ng-container *ngIf="deviceStatus">
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.kktBlock' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.blocked ? 'fiscalization.yes' : 'fiscalization.no') | transloco}}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.lidOpen' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.coverOpened ? 'fiscalization.yes' : 'fiscalization.no') | transloco}}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.paper' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.paperPresent ? 'fiscalization.yes' : 'fiscalization.no') | transloco}}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.kktFiscalization' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.fiscal ? 'fiscalization.yes' : 'fiscalization.no') | transloco }}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.fnFiscalization' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.fnFiscal ? 'fiscalization.yes' : 'fiscalization.no') | transloco }}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.fnDiscovered' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.fnPresent ? 'fiscalization.yes' : 'fiscalization.no') | transloco }}
                  </div>
                </div>
                <div class="form-group m-form__group row">
                  <label class="col-6 col-form-label">
                    {{ 'fiscalization.cashDrawer' | transloco }}
                  </label>
                  <div class="col-6 col-form-label">
                    {{ (deviceStatus.cashDrawerOpened ? 'fiscalization.yes' : 'fiscalization.no') | transloco }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #templateRef let-modal>
  <div class="modal-body">
    <p>{{ 'fiscalization.confirmQuestion' | transloco }}?</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-success m--margin-right-10" (click)="confirmModal(modal)">{{ 'fiscalization.yes' | transloco }}</button>

    <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{ 'fiscalization.no' | transloco }}</button>
  </div>
</ng-template>
