import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HxAuthService, HxInvoiceService, InvoiceModel, InvoiceStatus, isoDate } from 'hx-services';
import { BalanceService } from '@cashbox-app/service/balance.service';
import { Subscription } from 'rxjs';
import { BalanceNewModal } from '@cashbox-app/modal/balance-new/balance-new.modal';
import { InvoiceModal } from '@cashbox-app/modal/invoice/invoice.modal';
import { invoiceStatusMap } from '@cashbox-app/utils/util';

/**
 * Component of invoice list and current balance by stores
 *
 * @author Talgat Uspanov talgat.uspanov@yandex.kz
 */
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css'],
})
export class InvoiceListComponent implements OnInit {
  list: InvoiceModel[] = [];
  pagination = {
    currentPage: 1,
    allItemCount: 0,
    limit: 20,
    maxPage: 1
  };
  isLoading = {
    list: true,
  };
  readonly statusList = invoiceStatusMap;
  date = isoDate(Date.now());
  invoiceStatus: InvoiceStatus = InvoiceStatus.SENT;
  invoiceStatusList =  [
    InvoiceStatus.NEW,
    InvoiceStatus.SENT,
    InvoiceStatus.ACCEPTED,
    InvoiceStatus.REJECTED,
    InvoiceStatus.CANCELLED,
    InvoiceStatus.DISCARDED,
  ];
  private storeId!: number;
  private sub!: Subscription;

  constructor(
    private invoiceService: HxInvoiceService,
    private modalService: NgbModal,
    private aRoute: ActivatedRoute,
    private router: Router,
    private balanceService: BalanceService,
    private auth: HxAuthService,
  ) { }

  ngOnInit() {
    this.storeId = this.auth.user.store.id;

    this.sub = this.aRoute.queryParams.subscribe(params => {
      this.date = params['date'] ?? undefined;
      this.invoiceStatus = params['status'] || 'SENT';
      this.pagination.currentPage = params['page'] ? Number(params['page']) : 1;
      this.loadList();
    });
  }

  viewInvoice(invoice: InvoiceModel): void {
    const modalInstance = this.modalService.open(InvoiceModal, {size: 'lg'});
    modalInstance.componentInstance.invoice = invoice;
    modalInstance.result.then(() => {
      this.loadList();
      this.balanceService.balanceUpdated();
    }, () => {});
  }

  exportBalance(): void {
    const modalRef = this.modalService.open(BalanceNewModal, {size: 'lg'});
    modalRef.componentInstance.storeId = this.storeId;
    modalRef.result.then(() => {
      this.loadList();
      this.balanceService.balanceUpdated();
    }, () => {});
  }

  resetFilter() {
    this.date = undefined;
    this.invoiceStatus = InvoiceStatus.SENT;
    this.pagination.currentPage = 1;
    this.navigate();
  }

  navigate() {
    this.router.navigate(['./'], {
      queryParams: {
        date: this.date,
        page: this.pagination.currentPage,
        status: this.invoiceStatus,
      },
      relativeTo: this.aRoute
    });
  }

  pageChanged(event: number) {
    this.pagination.currentPage = event ?? 1;
    this.navigate();
  }

  private loadList(): void {
    this.isLoading.list = true;
    this.list = [];
    this.invoiceService.getInvoiceList({
      page: this.pagination.currentPage,
      storeId: this.storeId,
      date: this.date,
      statuses: [this.invoiceStatus],
    }).subscribe(result => {
      this.list = result.list;
      this.isLoading.list = false;
      this.pagination.allItemCount = result.count;
    }, () => this.isLoading.list = false);
  }

  applyFilters() {
    this.pagination.currentPage = 1;
    this.navigate();
  }
}
