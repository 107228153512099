<div class="modal-header">
  <h3 class="modal-title">{{ 'balance-new.export' | transloco }}</h3>

  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-portlet__body">
    <div class="row">
      <div class="col-md-12">
        <form class="m-form m-form--state" [formGroup]="invoiceForm" (ngSubmit)="onSubmitForm()">
          <div class="m-form__section">
            <div class="form-group m-form__group row">
              <label class="col-form-label col-lg-3 col-sm-12 is--required">
                {{ 'balance-new.shop' | transloco }}:
              </label>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <ng-select [formControlName]="invoiceFormKeys.toStoreId"
                           [placeholder]="'header.chooseStore' | transloco" [clearable]="true">
                  <ng-option *ngFor="let op of stores" [value]="op.id">{{op.title | uiLabel}}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-form-label col-lg-3 col-sm-6">
                {{ 'balance-new.note' | transloco }}:
              </label>

              <div class="col-lg-8 col-md-6 col-sm-12">
                <textarea id="cancelReason" class="form-control m-input" [formControlName]="invoiceFormKeys.description"></textarea>
              </div>
            </div>

            <div class="form-group m-form__group m-form__group--colored row">
              <label class="col-form-label col-lg-3 col-sm-6 is--required">
                {{ 'balance-new.products' | transloco }}:
              </label>

              <div class="col-lg-3 col-md-3 col-sm-6">
                <button (click)="addItem()" type="button" class="btn btn-success m-btn m-btn--icon m--margin-bottom-15">
                  <span>
                    <i class="la la-plus"></i>
                    <span>
                      {{ 'buttons.addProducts' | transloco }}
                    </span>
                  </span>
                </button>
              </div>
            </div>

            <div class="form-group m-form__group form-group m-form__group--colored m-form__group--last m-form__group--first" [formArrayName]="invoiceFormKeys.products" *ngFor="let item of asFormArray(invoiceFormKeys.products).controls; let ix = index;">
              <ng-container [formGroupName]="ix">
                <div class="row m--padding-bottom-15">

                  <div class="col-lg-7 col-md-4 col-sm-6">
                    <app-store-products-search [storeId]="storeId" [productInfoTypes]="productInfoTypes"
                                               (itemSelect)="onStoreProductSelected($event, ix)"></app-store-products-search>
                  </div>
                  <div class="col-lg-3 col-md-2">
                    <input type="text" [formControlName]="productFormKeys.amount" class="form-control m-input" [placeholder]="'balance-new.number' | transloco" [title]="'balance-new.number' | transloco">
                  </div>

                  <div class="col-lg-1 col-md-1 col-sm-1 text-left">
                    <button (click)="removeItem(ix)" type="button" style="margin-right: -20px;" [title]="'balance-new.deleteProduct' | transloco" class="m-portlet__nav-link btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                      <i class="la la-trash"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="alert alert-danger" *ngIf="invoiceForm.invalid">{{ 'balance-new.error' | transloco }}</div>

          <div class="m-form__actions m-form__actions--paddingless-sides">
            <div class="row">
              <div class="col-12 text-right">
                <button [disabled]="isLoading.submit" type="submit" class="btn btn-success">
                  {{ 'buttons.send' | transloco }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="isLoading.submit" class="m-page-loader m-page-loader--non-block m-page-loader--relative">
      <div class="m-loader m-loader--brand"></div>
    </div>
  </div>
</div>
