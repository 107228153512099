import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

/**
 * Component of cancel order Button with modal and reason textarea
 */
@Component({
  selector: 'app-order-cancel',
  templateUrl: './order-cancel.modal.html',
  styleUrls: ['./order-cancel.modal.css']
})
export class OrderCancelModal {
  @Input() orderId!: number;
  isLoading = {
    cancel: false,
  };
  private cancelReason = '';

  constructor(
    private cashboxService: CashboxService,
    private errorHandler: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
    private fiscalizationService: FiscalizationService,
    private toastr: ToastrService,
    private tr: TranslocoService
  ) {
  }

  onCancelReasonChange(event: string) {
    this.cancelReason = event;
  }

  async submitCancel() {
    this.isLoading.cancel = true;
    try {
      const {refundOrderId} = await this.cashboxService.cancelOrder(this.orderId, this.cancelReason);
      if (refundOrderId) {
        this.fiscalizationService.fiscalizeIfOffline(refundOrderId).then(isFiscalized => {
          if (isFiscalized) {
            this.toastr.info(this.tr.translate('order-cancel.refundFiscalized'));
          }
        });
      }
      this.modalInstance.close(true);
    } catch (err: any) {
      this.errorHandler.handle(err);
    } finally {
      this.isLoading.cancel = false;
    }
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }
}
