<div class="modal-header">
  <h3 class="modal-title">{{ 'order-cancel.titleInfo' | transloco }}</h3>

  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <app-cancel-reason (cancelReasonChange)="onCancelReasonChange($event)"></app-cancel-reason>

  <div class="text-right m--margin-top-15">
    <button type="button" [disabled]="isLoading.cancel" (click)="submitCancel()"
            class="btn btn-success m--margin-right-10">
      <i *ngIf="isLoading.cancel" class="la la-spinner has--spin"></i>
      {{ 'invoice-modal.ok' | transloco }}
    </button>

    <button type="button" (click)="dismissModal()" class="btn btn-secondary">
      {{ 'invoice-modal.no' | transloco }}
    </button>
  </div>
</div>
