<div class="modal-header">
  <h3 class="modal-title">
    <strong>{{ 'split-product-modal.title' | transloco }}</strong>
  </h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="m-widget1 m--padding-0">
    <div class="m-widget1__item">
      <div class="m--padding-bottom-10 hx-quantity-list-container">
        <div class="hx-quantity-container" *ngFor="let sp of splitProductList; trackBy:trackById" [ngClass]="{'selected': sp.enabled}">
          <div (click)="chooseQuantity(sp)" class="hx-quantity-container__element m-widget1__title m-widget1__title--list m--margin-bottom-10">
            <div class="quantity-text">{{ sp.splitQuantity }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="not-available-shape-text" *ngIf="availableShapes && availableShapes.shapes.length === 0">
    {{ 'split-product-modal.divisionsAvailable' | transloco }}
  </div>

  <div *ngIf="availableShapes && availableShapes.shapes.length > 0">
    <div class="m--margin-bottom-20" *ngIf="availableShapes && availableShapes.shapes.length > 0">{{ 'split-product-modal.splitInto' | transloco }}</div>

    <div class="circle-group">
      <div class="circle-block" *ngFor="let q of quantityArr; let i = index" tabindex="0" (click)="onQuantitySelected(q)">
        <div class="circle-out" >
          <div class="progress" [ngStyle]="{'transform': q.transform }"></div>
          <div class="circle-in" [ngStyle]="{'background': q.conicGradient }">
            <div *ngFor="let r of q.progressRotate;" class="line" [ngStyle]="{'transform': r }"></div>
            <div *ngIf="q.transformLast" class="line" [ngStyle]="{'transform': q.transformLast }"></div>
          </div>
        </div>
        <div class="circle-name">
          <span class="circle-quantity-text"> {{ q.shapes.join(', ') }} {{ 'split-product-modal.pieces' | transloco }} </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button (click)="split()" [disabled]="!selectedProductSplit || isLoading.split" type="button" class="btn btn-outline-success">
    <span>{{ 'split-product-modal.divide' | transloco }}</span>
  </button>

  <button [disabled]="isLoading.cancel" (click)="cancelSplit()" type="button" class="btn btn-outline-danger">
    <span>{{ 'split-product-modal.cancelDivision' | transloco }}</span>
  </button>
</div>
