import { CalculateDiscountRequest, DeliveryType, DiscountResultModel, Entity } from 'hx-services';
import { format, formatISO } from 'date-fns';

export class OrderCashbox {
  id?: number;
  date = Date.now();
  // currentOrder
  pos = 0;
  cash = 0;
  epay = 0;
  checkingAccount = 0;
  // CashOrder
  address?: string;
  cityId?: number;
  storeId?: number;
  discounts: DiscountResultModel[] = [];
  products: any[] = [];
  debt = false;
  clientId?: number;
  subTotal?: number; // totalPrice
  total?: number;
  deliveryType = DeliveryType.PICKUP;
  otpVerified = false;

  constructor() { }

  getDiscountBody(): CalculateDiscountRequest {
    if (!this.storeId) {
      throw new Error('[ordercashbox]storeId.undefined');
    }
    return {
      results: this.discounts,
      order: {
        id: this.id,
        date: formatISO(this.date, {representation: 'date'}),
        time: format(this.date, 'HH:mm'),
        storeId: this.storeId,
        clientId: this.clientId,
        clientType: Entity.INDIVIDUAL,
        deliveryType: DeliveryType.PICKUP,
      },
      products: [],
    };
  }
}
