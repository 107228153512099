import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HxAuthService, HxInvoiceService, InvoiceModel, InvoiceStatus, SourceSystem, UiError } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice.modal.html'
})
export class InvoiceModal implements OnInit {

  @Input() invoice!: InvoiceModel;
  isReject = false;
  cancelReason?: string;
  isLoading = {
    invoice: false,
    accept: false,
    rejectOrCancel: false,
  };
  canCancel = false;
  canAccept = false;
  cancelButtonLabel?: string;
  private storeId!: number;

  constructor(
    public modal: NgbActiveModal,
    private invoiceService: HxInvoiceService,
    private errorHandler: ErrorHandlerService,
    private toastr: ToastrService,
    private auth: HxAuthService,
    private tr: TranslocoService
  ) {
  }

  ngOnInit(): void {
    this.storeId = this.auth.user.store.id;
    this.canCancel = this.invoice.status === InvoiceStatus.SENT;
    this.canAccept = this.invoice.status === InvoiceStatus.SENT && this.invoice.toStore?.id === this.storeId;
    this.cancelButtonLabel = this.getCancelButtonLabel();
    this.isLoading.invoice = true;
    this.invoiceService.getInvoiceById(this.invoice.id).subscribe(invoice => {
      this.invoice = invoice;
      this.isLoading.invoice = false;
    }, () => this.isLoading.invoice = false);
  }

  rejectOrCancelInvoice() {
    if (!this.cancelReason) {
      throw new UiError('invoice.cancelReason.required');
    }
    if (this.invoice.status === InvoiceStatus.SENT && this.invoice.fromStore?.id === this.storeId && this.invoice.sourceSystem === SourceSystem.CASHBOX) {
      this.cancelInvoice(this.cancelReason);
    } else {
      this.rejectInvoice(this.cancelReason);
    }
  }

  private rejectInvoice(reason: string) {
    this.isLoading.rejectOrCancel = true;
    this.invoiceService.rejectBalance(this.invoice.id, reason).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.rejectedSuccessfully'));
      this.isLoading.rejectOrCancel = false;
      this.modal.close();
    }, err => {
      this.isLoading.rejectOrCancel = false;
      this.errorHandler.handle(err);
    });
  }

  accept(): void {
    this.isLoading.accept = true;
    this.invoiceService.acceptInvoice(this.invoice.id).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.successfullyAccepted'));
      this.isLoading.accept = false;
      this.modal.close();
    }, err => {
      this.isLoading.accept = false;
      this.errorHandler.handle(err);
    });
  }

  private cancelInvoice(reason: string) {
    this.isLoading.rejectOrCancel = true;
    this.invoiceService.cancelInvoice(this.invoice.id, reason).subscribe(() => {
      this.toastr.success(this.tr.translate('invoice-modal.canceledSuccessfully'));
      this.cancelReason = '';
      this.isReject = false;
      this.isLoading.rejectOrCancel = false;
      this.modal.close();
    }, err => {
      this.isLoading.rejectOrCancel = false;
      this.errorHandler.handle(err);
    });
  }

  private getCancelButtonLabel(): string {
    if (this.invoice.status === InvoiceStatus.SENT && this.invoice.fromStore?.id === this.storeId && this.invoice.sourceSystem === SourceSystem.CASHBOX) {
      return this.tr.translate('invoice-modal.cancel');
    } else {
      return this.tr.translate('invoice-modal.reject');
    }
  }
}
