import { Component, OnDestroy, OnInit } from '@angular/core';
import { HxAuthService, NotifierService } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  constructor(
    public auth: HxAuthService,
    private notifierService: NotifierService,
    private tr: TranslocoService,
  ) {
  }

  ngOnInit() {
    this.notifierService.start();
    this.tr.setDefaultLang('ru');
    this.tr.setActiveLang('ru');
  }

  ngOnDestroy() {
    this.notifierService.destroy();
  }
}
