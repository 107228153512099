import { fromEvent as observableFromEvent, merge as observableMerge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class HeaderService {

  constructor() {}

  network() {
    return observableMerge(
      observableFromEvent(window, 'online').pipe(map((e: any) => e.type)),
      observableFromEvent(window, 'offline').pipe(map((e: any) => e.type))
    );
  }
}
