<div class="m-content">
  <div class="alert alert-danger m-alert m-alert--icon m-alert--air m-alert--square m--margin-bottom-30" role="alert" *ngIf="!cbOrder.storeId">
    <div class="m-alert__icon">
      <i class="la la-warning"></i>
    </div>
    <div class="m-alert__text">
      <b>{{ 'order-cashbox.attention' | transloco }}</b> {{ 'order-cashbox.alertText' | transloco }}.
    </div>
  </div>

  <div class="row">
    <div class="col-md-5 m--padding-right-0">
      <div class="m-portlet m-portlet--full-height m-portlet--brand">
        <div class="hc-cart">
          <div class="hc-cart__empty" *ngIf="!cart.length">
            {{ 'order-cashbox.addProduct' | transloco }}
          </div>

          <hc-cart-list *ngIf="cbOrder.id && cbOrder.storeId && currency && cbOrder.total !== undefined && cbOrder.subTotal !== undefined"
                        [cart]="cart"
                        [orderId]="cbOrder.id"
                        [orderTotal]="cbOrder.total"
                        [orderSubTotal]="cbOrder.subTotal"
                        [discounts]="cbOrder.discounts"
                        [storeId]="cbOrder.storeId"
                        [currency]="currency"
                        (update)="updateCart($event)"
                        (checkDiscount)="updateDiscount()">
          </hc-cart-list>

          <div class="row" *ngIf="cart?.length">
            <div class="col-md-12">
              <button type="button" *ngIf="!isClientShow" class="btn btn-primary btn-sm" (click)="addClient()">{{ 'buttons.addClient' | transloco }}</button>
              <hx-client-checker *ngIf="isClientShow && cbOrder.cityId" [cityId]="cbOrder.cityId" (clientChange)="updateClient($event)"></hx-client-checker>
            </div>
          </div>

          <hc-otp *ngIf="cbOrder.clientId && cbOrder.id" [orderId]="cbOrder.id" [clientId]="cbOrder.clientId" (otpConfirm)="onOtpConfirmed()"></hc-otp>

          <ng-container *ngIf="canUnderSalary()">
            <div class="row m--margin-top-10">
              <div class="col-md-12">
                <label for="underSalary" class="m--margin-right-10" style="font-weight: 400;vertical-align: middle;">
                  {{ 'order-cashbox.borrowed' | transloco }}:
                </label>

                <span class="m-switch m-switch--icon m-switch--outline m-switch--success ">
                  <label>
                    <input type="checkbox" checked="checked" id="underSalary" [(ngModel)]="cbOrder.debt">
                    <span></span>
                  </label>
                </span>
              </div>
            </div>

            <div class="m--margin-top-10">
              <small>{{ 'order-cashbox.confirmEmployee' | transloco }}</small>
            </div>
          </ng-container>

          <div class="row m--margin-top-10" *ngIf="cart?.length && cbOrder.id">
            <div class="col-md-12">
              <hx-promo-code [orderId]="cbOrder.id" [discounts]="cbOrder.discounts"></hx-promo-code>
            </div>
          </div>

          <div class="hc-cart__button-group" *ngIf="cart?.length">
            <button type="button" [disabled]="(loaderService.isLoading | async) || isLoading.sell" (click)="soldOrder()"
                    class="btn btn-success btn-lg">
              <span *ngIf="loaderService.isLoading | async" class="la la-spinner has--spin"></span>
              <span *ngIf="loaderService.isLoading | async">{{ 'order-cashbox.calculating' | transloco }}</span>
              <span *ngIf="(loaderService.isLoading | async) === false">{{ 'order-cashbox.sell' | transloco }}</span>
            </button>

            <button type="button" [disabled]="(loaderService.isLoading | async) || isLoading.sell" (click)="postponeOrder()"
                    class="btn btn-outline-primary btn-lg">
              <span *ngIf="isLoading.sell" class="la la-spinner has--spin"></span>
              {{ 'order-cashbox.postpone' | transloco }}
            </button>
          </div>

          <div class="m--margin-top-50" *ngIf="cart?.length && currencySymbol">
            <h4 class="m--margin-bottom-15">{{ 'order-cashbox.nomenclature' | transloco }}</h4>

            <app-product-kind-list [currencySymbol]="currencySymbol" [storeId]="cbOrder.storeId" (productAdded)="onOptionalProductAdded($event)"></app-product-kind-list>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isLoading.order" class="col-md-7">
      <div class="m-portlet m-portlet--full-height m-portlet--brand">
        <div class="row">
          <div class="col-md-12">
            <button *ngIf="!cbOrder.id" [disabled]="isLoading.generateCart" (click)="generateCart()" type="button"
                    class="btn btn-success btn-md m--margin-bottom-10">
              {{ 'order-cashbox.book' | transloco }}
            </button>

            <a *ngIf="productActiveCart" (click)="activeSplitProduct()" class="m-nav__link disabled-link m--margin-left-20">
              <span class="m-nav__link-title">
                <span class="m-nav__link-wrap">
                  <span class="m-nav__link-text">
                    {{ 'order-cashbox.separation' | transloco }}...
                  </span>
                </span>
              </span>
            </a>

            <button *ngIf="cbOrder.id" [disabled]="isLoading.prolongCartExpiration" (click)="prolongCartExpiration()" class="btn btn-warning btn-md m--margin-bottom-10">
              <i class="la la-shopping-cart"></i>{{ 'order-cashbox.extend' | transloco }}              {{ countDown | countDown }}
            </button>

            <button *ngIf="cbOrder.id" [disabled]="isLoading.cancelCart" (click)="cancelCart()" class="btn btn-default btn-md m--margin-bottom-10 pull-right">
              {{ 'order-cashbox.cancel' | transloco }}
            </button>
          </div>
        </div>
        <hx-product-list *ngIf="currency"
          [cartProducts]="cartProducts"
          [date]="date"
          [store]="store"
          [currency]="currency"
          (productAdded)="onProductAdded($event)"
          (splitSelected)="onSplitSelected($event)">
        </hx-product-list>
      </div>
    </div>
  </div>
</div>

<hx-app-order-check [printCount]="timesToPrint"></hx-app-order-check>
