<div class="modal-header">
  <h3 class="modal-title">{{ 'order-product-cancel.title' | transloco }}</h3>
  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div class="cb-soldmodal" *ngIf="!order.debt">
    <div *ngIf="hasCash" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">{{ 'order-product-cancel.cash' | transloco }}</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" [disabled]="!isMixedPayment" [(ngModel)]="order.cash" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="hasPos" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">{{ 'order-product-cancel.pos' | transloco }}</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" [disabled]="!isMixedPayment" [(ngModel)]="order.pos" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="hasKaspiPos" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">{{ 'order-product-cancel.kaspiPos' | transloco }}</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" [disabled]="!isMixedPayment" [(ngModel)]="order.kaspiPos" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="hasCoin" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">{{ 'order-product-cancel.coin' | transloco }}</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" [disabled]="!isMixedPayment" [(ngModel)]="order.coin" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="order.forte && order.forte > 0" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">{{ 'order-product-cancel.site' | transloco }} (forte)</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" disabled [(ngModel)]="order.forte" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>

    <div *ngIf="order.kaspi && order.kaspi > 0" class="cb-soldmodal__line">
      <div class="cb-soldmodal__data">Kaspi.kz</div>

      <div class="cb-soldmodal__value">
        <div class="input-group" style="width: 200px;">
          <input type="number" class="form-control" disabled [(ngModel)]="order.kaspi" [placeholder]="'cashbox-operation.placeholderSum' | transloco">
          <span class="input-group-addon">{{currencySymbol}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="m-widget1 m--padding-0 m--padding-right-15">
    <table class="table table-light">
      <thead>
      <tr>
        <th>{{ 'cashbox-operation.productName' | transloco }}</th>
        <th style="width: 90px;">{{ 'cashbox-operation.weight' | transloco }}</th>
        <th style="width: 30px;">{{ 'cashbox-operation.before' | transloco }}</th>
        <th style="width: 150px;" class="text-center">{{ 'cashbox-operation.after' | transloco }}</th>
        <th style="width: 90px;" class="text-right">{{ 'cashbox-operation.sum' | transloco }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let i = index; let cartProduct of products; trackBy: trackByFn">
        <td>
          {{ cartProduct.productInfo.title | uiLabel }} <span *ngIf="cartProduct.price.amount !== 1">{{ cartProduct.price.amount }}</span>

          <ng-container *ngIf="cartProduct.discounts">
            <button *ngFor="let discount of cartProduct.discounts; let i = index"
                    class="btn btn-sm product-discount" [ngClass]="{'m--text-line-through': !discount.enabled}">
              {{ discount.promoTitle | uiLabel }}
              <span class="m-widget1__number m--font-success">
                <!-- TODO @deedarb #ins other result types ? -->
                <span *ngIf="discount.resultType === 'PERCENT_DISCOUNT'">{{ discount.promoValue }} % ({{ discount.value | uiCurrency: currency }})</span>
                <span *ngIf="discount.resultType === 'MONEY_DISCOUNT'">{{ discount.value | uiCurrency: currency }}</span>
              </span>
              ({{discount.productAmount}})
            </button>
          </ng-container>
        </td>
        <td>
          {{cartProduct.weight | weight}}
        </td>
        <td>
          {{cartProduct.beforeAmount}}
        </td>
        <td>
          <div class="input-group m-form__group" style="width: 100px;">
            <button (click)="subtractProductAmount(cartProduct)" type="button" [disabled]="isLoading.discounts || cartProduct.afterAmount === 0" class="btn btn-default input-group-addon">
              <i class="la la-minus"></i>
            </button>
            <label class="text-center min-width-60">{{cartProduct.afterAmount}}</label>
            <button (click)="addProduct(cartProduct)" [disabled]="isLoading.discounts" type="button" class="btn btn-default input-group-addon">
              <i class="la la-plus"></i>
            </button>
          </div>
        </td>
        <td class="text-right">
          {{cartProduct.value}}
        </td>
      </tr>
      <tr>
        <td colspan="3">
          {{ 'cashbox-operation.total' | transloco }}:
        </td>
        <td class="text-center">
          {{ products.length }}
        </td>
        <td class="text-right">
          <span class="m-widget1__number" [ngClass]="{'m--font-accent': order.subTotal !== order.total}">
            <span *ngIf="order.subTotal !== order.total" class="m--text-line-through">{{ order.subTotal | uiCurrency: currency }}</span>
            {{ order.total | uiCurrency: currency }}
          </span>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="m--margin-top-15">
      <app-cancel-reason (cancelReasonChange)="onCancelReasonChange($event)"></app-cancel-reason>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="cp__actions">
    <button type="button" [disabled]="isDisabled || hasPartialRefunds || isLoading.discounts" (click)="cancelOrder()" class="btn btn-danger">
      <i *ngIf="hasPartialRefunds" [ngbTooltip]="'order-product-cancel.orderReturn' | transloco" class="la la-info-circle"></i> {{ 'order-product-cancel.cancelOrder' | transloco }}
    </button>

    <button type="button" [disabled]="isDisabled || isLoading.discounts" (click)="updateOrder()" class="btn btn-success">
      {{ 'order-product-cancel.ok' | transloco }}
    </button>
  </div>
</div>
