import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { HxError, UiError } from 'hx-services';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    private toastr: ToastrService,
    private tr: TranslocoService,
  ) {
  }

  handle(err: any) {
    if (err instanceof HttpErrorResponse) {
      const ex: HxError = err.error?.message ? err.error as HxError : {
        code: 577,
        id: '-',
        message: err.message,
        data: err.error ?? {},
      };
      this.toastr.error(ex.description ?? this.tr.translate(ex.message, ex.data), this.tr.translate('error') + ' ' + ex.id);
    } else if (err instanceof UiError) {
      this.toastr.error(this.tr.translate(err.message, err.data ?? {}));
    } else {
      console.error(err);
      this.toastr.error(this.tr.translate('err.unexpected'));
    }
  }
}
