import { Component, OnInit, TemplateRef, TrackByFunction, ViewChild } from '@angular/core';
import { FiscalizationService } from '@cashbox-app/service/fiscalization.service';
import { AtolDeviceInfo, AtolDeviceStatus, AtolFiscalResult, HxAuthService, NonFiscalizedOrderModel } from 'hx-services';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { firstValueFrom } from 'rxjs';

interface NonFiscalizedOrderTask extends NonFiscalizedOrderModel {
  isFiscalizing?: boolean;
  fiscalled?: boolean;
  error?: { code: number, description: string };
}

@Component({
  selector: 'app-fiscalization',
  templateUrl: './fiscalization.component.html',
  styleUrls: ['./fiscalization.component.css']
})
export class FiscalizationComponent implements OnInit {
  @ViewChild('templateRef') public templateRef!: TemplateRef<any>;
  deviceInfo?: AtolDeviceInfo;
  deviceStatus?: AtolDeviceStatus;
  nonFiscalizedOrders: NonFiscalizedOrderTask[] = [];
  timezone!: string;
  isLoading = {
    checkStatus: false,
    openShift: false,
    closeShift: false,
    paper: false,
    fiscalizeAll: false,
  };
  isFiscalPaperReady = false;

  constructor(
    private fiscalizationService: FiscalizationService,
    private toastr: ToastrService,
    private cashboxService: CashboxService,
    private auth: HxAuthService,
    private tr: TranslocoService,
    private modal: NgbModal,
    private errorService: ErrorHandlerService,
  ) {
  }

  ngOnInit(): void {
    this.timezone = this.auth.user.store.timezone;
    this.fiscalizationService.getDeviceInfo().subscribe(deviceInfo => {
      this.deviceInfo = deviceInfo;
    });
    this.loadFiscalPaperState();
    this.loadNonFiscalizedOrders();
    this.fiscalizationService.getDeviceStatus().subscribe(deviceStatus => this.deviceStatus = deviceStatus);
  }

  closeShift() {
    if (!this.deviceStatus || !this.deviceInfo) {
      throw new Error('device.loading');
    }
    if (!['opened', 'expired'].includes(this.deviceStatus.shift)) {
      this.toastr.error(this.tr.translate('fiscalization.shiftNotOpen'));
      return;
    }
    this.isLoading.closeShift = true;
    this.fiscalizationService.closeShift().subscribe(result => {
      this.toastr.info(this.tr.translate('fiscalization.shiftClosed'));
      this.fiscalizationService.getDeviceStatus().subscribe(async deviceStatus => {
        this.deviceStatus = deviceStatus;
        result.serialNumber = this.deviceInfo?.serial;
        await this.cashboxService.saveCloseShiftEvent(result);
        console.log('saved close shift info');
      });
    }, err => {
    }, () => this.isLoading.closeShift = false);
  }

  openShift() {
    if (!this.deviceStatus || !this.deviceInfo) {
      throw new Error('device.loading');
    }
    if (!['closed'].includes(this.deviceStatus.shift)) {
      this.toastr.error(this.tr.translate('fiscalization.shiftNotClosed'));
      return;
    }
    this.isLoading.openShift = true;
    this.fiscalizationService.openShift().subscribe(result => {
      console.log('open shift result', result);
      this.toastr.info(this.tr.translate('fiscalization.shiftOpen'));
      this.fiscalizationService.getDeviceStatus().subscribe(deviceStatus => this.deviceStatus = deviceStatus);
    }, err => {
    }, () => this.isLoading.openShift = false);
  }

  async fiscalize(ord: NonFiscalizedOrderTask) {
    ord.isFiscalizing = true;
    try {
      await this.fiscalizationService.fiscalizeIfOffline(ord.id);
      this.toastr.success(this.tr.translate('fiscalization.orderFiscalization', {orderNumber: ord.uniqueNumber}));
      this.loadNonFiscalizedOrders();
    } catch (err: any) {
      ord.isFiscalizing = false;
      if (err instanceof HttpErrorResponse) {
        ord.error = err.error.error;
      } else {
        ord.error = {code: 1000, description: 'Ошибка: ' + JSON.stringify(err)};
      }
    } finally {
      ord.isFiscalizing = false;
    }
  }

  async fiscalizeAll() {
    try {
      this.isLoading.fiscalizeAll = true;
      this.nonFiscalizedOrders = await this.cashboxService.getNotFiscalizedOrders();
      for (let ord of this.nonFiscalizedOrders) {
        ord.isFiscalizing = true;
        try {
          await this.fiscalizationService.fiscalizeIfOffline(ord.id);
          this.toastr.success(this.tr.translate('fiscalization.orderFiscalization', {orderNumber: ord.uniqueNumber}));
          ord.fiscalled = true;
        } finally {
          ord.isFiscalizing = false;
        }
      }
    } finally {
      this.isLoading.fiscalizeAll = false;
    }
  }

  trackByFn: TrackByFunction<NonFiscalizedOrderTask> = (index, obj) => obj.id;

  confirmNoPaper() {
    this.modal.open(this.templateRef, {size: 'md'});
  }

  async confirmModal(modal: any) {
    const {paperPresent} = await firstValueFrom(this.fiscalizationService.getDeviceStatus());
    if (paperPresent) {
      this.isLoading.paper = true;
      try {
        await this.cashboxService.setFiscalPaperReady();
        this.loadFiscalPaperState();
        this.loadNonFiscalizedOrders();
        this.cashboxService.handleFiscalPaperReadyEvent(true);
        modal.dismiss();
        this.toastr.info(this.tr.translate('fiscalization.canFiscalizeOrders'));
      } catch (err: any) {
        this.errorService.handle(err);
      } finally {
        this.isLoading.paper = false;
      }
    } else {
      this.toastr.error(this.tr.translate('fiscalization.noFiscalPaper'));
    }
  }

  private async loadNonFiscalizedOrders() {
    this.nonFiscalizedOrders = await this.cashboxService.getNotFiscalizedOrders();
    this.nonFiscalizedOrders.forEach(order => {
      order.isFiscalizing = true;
      this.fiscalizationService.getRequestResult<{ fiscalParams: AtolFiscalResult }>(order.eventUuid).subscribe(result => {
        if (result.fiscalParams) {
          this.fiscalizationService.saveAtolFiscalizationData(order.id, result.fiscalParams).subscribe(() => order.isFiscalizing = false);
        } else {
          order.isFiscalizing = false;
        }
      }, err => {
        order.isFiscalizing = false;
        if (err instanceof HttpErrorResponse) {
          const error = err.error as { code: number, description: string };
          if (error.description === this.tr.translate('fiscalization.description', {order: order.eventUuid, deviceInfo: this.deviceInfo?.serial})) {
            console.log('ignore error', error, order.eventUuid);
          } else {
            order.error = error;
          }
        } else {
          order.error = {code: 1000, description: 'Ошибка: ' + JSON.stringify(err)};
        }
      });
    });
  }

  private loadFiscalPaperState() {
    this.cashboxService.getFiscalPaperState().then(result => {
      this.isFiscalPaperReady = result.ready;
    });
  }
}
