<div class="modal-header">
  <h3 class="modal-title">{{ 'order-invoice-confirmation.titleText' | transloco }}</h3>

  <button class="close" type="button" (click)="dismissModal()">
    <span>×</span>
  </button>
</div>

<div class="modal-body">
  <div>
    {{orderNumbers}}
  </div>

  <div class="text-right m--margin-top-15">
    <button type="button" [disabled]="isLoading.cancel" (click)="closeModal()"
            class="btn btn-success m--margin-right-10">
      {{ 'order-invoice-confirmation.ok' | transloco }}
    </button>

    <button type="button" (click)="dismissModal()" class="btn btn-secondary">
      {{ 'order-invoice-confirmation.no' | transloco }}
    </button>
  </div>
</div>
