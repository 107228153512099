<div class="row m--margin-bottom-20">
  <div class="col-md-12">
    <ul class="category-list">
      <li *ngFor="let item of productInfoTypes" class="category-list__item"
          [ngClass]="{'category-list__item--active': productInfoTypeId === item.id}">
        <a (click)="selectProductInfoType(item.id, item.types)" class="category-list__link">
          {{ item.label | transloco }}
        </a>
      </li>
    </ul>
    <hr class="hr">
  </div>
  <div class="col-md-4" *ngIf="productInfoTypeId === 'storage'">
    <hx-product-category-select [(ngModel)]="categoryIds" [brandId]="store.brandId" [default]="true" [multiple]="true"
                                (selectChange)="onProductCategoryChanged()"></hx-product-category-select>
  </div>

  <div class="col-md-4">
    <form class="m-form" (ngSubmit)="searchProduct()">
      <div class="input-group">
        <input required type="text" [(ngModel)]="productInfoTitle" name="productInfoTitle"
               class="form-control m-input" [placeholder]="'balance-view.searchProduct' | transloco">

        <div (click)="searchProduct()" class="input-group-btn" style="margin-left: -30px;z-index: 5;">
          <i class="la la-search"></i>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-2">
    <button (click)="resetFilters()" class="btn btn-primary hc-button hc-button--secondary" type="button">
      <hc-icon icon="refresh"></hc-icon>
      <span>{{ 'buttons.reset' | transloco }}</span>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="product-wrap" *ngIf="products.length && !isLoading.storeBalance">
      <ng-container class="m-nav__item" *ngFor="let product of products">
        <app-store-product *ngIf="product.productInfo?.id && product.amount" class="product-wrap__item"
                           [product]="product"></app-store-product>
      </ng-container>
    </div>

    <hx-loader [isLoading]="isLoading.storeBalance" [length]="products.length"></hx-loader>
  </div>
</div>
