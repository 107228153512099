import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { CashboxService } from '@cashbox-app/service/cashbox.service';
import { debounceTime } from 'rxjs/operators';
import { CurbsidePickupModel } from 'hx-services';

@Component({
  selector: 'hc-curbside-pickup',
  templateUrl: './curbside-pickup.component.html',
  styleUrls: ['./curbside-pickup.component.css']
})
export class CurbsidePickupComponent implements OnInit {
  @HostBinding('style.bottom')
  bottomOffset = '5vh';

  curbsidePickups: CurbsidePickupModel[] = [];
  bottom = true;
  isPlaying = false;
  private playTimeout?: NodeJS.Timeout;

  constructor(
    private cashboxService: CashboxService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  async ngOnInit() {
    this.cashboxService.curbsidePickupObs.pipe(debounceTime(700)).subscribe(async (play: boolean) => {
      await this.loadActiveCurbsidePickups();
      if (play) {
        this.isPlaying = true;
        const playFn = async () => {
          const rn = Math.floor(Math.random() * 100);
          if (rn === 42) {
            await new Audio('/assets/audio/duck_short_1.mp3').play();
          } else {
            await new Audio('/assets/audio/curbside.wav').play();
          }
        };
        playFn();
        clearInterval(this.playTimeout);
        this.playTimeout = setInterval(() => playFn(), 5000);
      }
    });
    await this.loadActiveCurbsidePickups();
  }

  stopPlaying() {
    this.isPlaying = false;
    clearInterval(this.playTimeout);
  }

  moveUp() {
    this.bottom = false;
    this.bottomOffset = '65vh';
  }

  moveDown() {
    this.bottom = true;
    this.bottomOffset = '5vh';
  }

  async cashierSeen(cp: CurbsidePickupModel) {
    this.stopPlaying();
    await this.cashboxService.markCurbsidePickupAsSeen(cp.id);
    await this.loadActiveCurbsidePickups();
  }

  private async loadActiveCurbsidePickups() {
    this.curbsidePickups = await this.cashboxService.getCurbsidePickups({active: true});
    console.log('[curbside-pickup] load active curbside pickups', this.curbsidePickups);
    this.cdr.markForCheck();
  }
}
