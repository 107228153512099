import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  private updateBalanceSubject = new Subject<void>();
  readonly updateBalanceObs = this.updateBalanceSubject.asObservable();

  balanceUpdated() {
    this.updateBalanceSubject.next();
  }
}
