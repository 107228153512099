import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from '@cashbox-app/service/error-handler.service';
import { CashboxService } from '@cashbox-app/service/cashbox.service';

@Component({
  selector: 'app-order-invoice-confirmation',
  templateUrl: './order-invoice-confirmation.modal.html',
  styleUrls: ['./order-invoice-confirmation.modal.css']
})
export class OrderInvoiceConfirmationModal {
  @Input() orderNumbers!: string;
  isLoading = {
    cancel: false,
  };

  constructor(
    private cashboxService: CashboxService,
    private errorHandler: ErrorHandlerService,
    private modalInstance: NgbActiveModal,
  ) {
  }

  closeModal() {
    this.modalInstance.close();
  }

  dismissModal() {
    this.modalInstance.dismiss();
  }
}
