<div class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark">
  <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow">
    <li class="m-menu__section">
      <h4 class="m-menu__section-text">
        {{ 'sidebar.orders' | transloco }}
      </h4>
      <i class="m-menu__section-icon la la-ellipsis-h"></i>
    </li>

    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/sale" class="m-menu__link">
        <i class="m-menu__link-icon la la-shopping-cart"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.sale' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>
    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/orders/today" class="m-menu__link">
        <i class="m-menu__link-icon la la-list-alt"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.today' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>
    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/orders/list" [queryParams]="{activeCart: false}" class="m-menu__link">
        <i class="m-menu__link-icon la la-list-ul"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.allOrders' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>
    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }" aria-haspopup="true" >
      <a routerLink="/delivery" class="m-menu__link">
        <i class="m-menu__link-icon la la-truck"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.delivery' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>
    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/money-report" class="m-menu__link">
        <i class="m-menu__link-icon la la-money"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.revenue' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/orders/archive" [queryParams]="{actions: 'RECEIVED'}" class="m-menu__link">
        <i class="m-menu__link-icon la la-archive"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.ordersStore' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li *ngIf="isFiscalizationVisible" class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a routerLink="/fiscalization" class="m-menu__link">
        <i class="m-menu__link-icon la la-archive"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text" [ngClass]="{'color-red': !isFiscalPaperReady}">
              {{ 'sidebar.fiscalization' | transloco }}
            </span>
          </span>
        </span>
      </a>
      <span *ngIf="!isFiscalPaperReady" class="no-paper">{{ 'sidebar.noFiscalPaper' | transloco }}</span>
    </li>

    <li class="m-menu__section">
      <h4 class="m-menu__section-text">
        {{ 'sidebar.control' | transloco }}
      </h4>
      <i class="m-menu__section-icon la la-ellipsis-h"></i>
    </li>

    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="m-menu__link" routerLink="/invoices">
        <i class="m-menu__link-icon la la-file-text"></i>
        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.accounting' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="m-menu__link" routerLink="/faq/list">
        <i class="m-menu__link-icon la la-bell"></i>
        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.faq' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__section">
      <h4 class="m-menu__section-text">
        {{ 'sidebar.accounting' | transloco }}
      </h4>
      <i class="m-menu__section-icon la la-ellipsis-h"></i>
    </li>

    <li class="m-menu__item">
      <a [href]="profileUrl" target="_blank" class="m-menu__link">
        <i class="m-menu__link-icon la la-male"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.profile' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__item" routerLinkActive="m-menu__item--active" [routerLinkActiveOptions]="{ exact: true }">
      <a class="m-menu__link" routerLink="/options">
        <i class="m-menu__link-icon la la-gear"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.settings' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>

    <li class="m-menu__item m--visible-tablet-and-mobile">
      <a (click)="logout()" class="m-menu__link">
        <i class="m-menu__link-icon la la-sign-out"></i>

        <span class="m-menu__link-title">
          <span class="m-menu__link-wrap">
            <span class="m-menu__link-text">
              {{ 'sidebar.out' | transloco }}
            </span>
          </span>
        </span>
      </a>
    </li>
  </ul>
</div>
