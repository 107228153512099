<header class="m-grid__item m-header" data-minimize-mobile="hide" data-minimize-offset="200" data-minimize-mobile-offset="200" appunwraptag="">
  <div class="m-container m-container--fluid m-container--full-height">
    <div class="m-stack m-stack--ver m-stack--desktop">
      <!-- BEGIN: Brand -->
      <div class="m-stack__item m-brand  m-brand--skin-dark" [ngClass]="{'is--test': !isProd}">
        <div class="m-stack m-stack--ver m-stack--general">
          <div class="m-stack__item m-stack__item--middle m-brand__logo">
            <a (click)="reload()" class="m-brand__logo-wrapper">
              <img src="./assets/images/logo-big.png" alt="logo">
            </a>
          </div>

          <div class="m-stack__item m-stack__item--middle m-brand__tools">
            <h5 class="m-menu__link-text m--margin-right-10 m--visible-tablet-and-mobile-inline-block" (click)="changeStore()">
              <small class="m--hidden-sm">{{ 'header.shopSign' | transloco }}</small> {{ currentStore?.title | uiLabel }}
            </h5>

            <a (click)="toggleSidebar()" class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
              <span></span>
            </a>
            <!-- BEGIN: Responsive Aside Left Menu Toggler -->
            <a (click)="toggleMobSidebar()" class="open-sidebar m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
              <span class="open-sidebar"></span>
            </a>
            <!-- END -->
          </div>
        </div>
      </div>
      <!-- END: Brand -->
      <div class="m-stack__item m-stack__item--fluid m-header-head" [ngClass]="{'is--test': !isProd}" attr.data-test="{{ 'site.is.test' | transloco}}">
        <!-- BEGIN: Topbar -->
        <div id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark ">
          <ul class="m-menu__nav  m-menu__nav--submenu-arrow" *ngIf="currentStore">
            <li *ngIf="currentStore.title" class="m-menu__item  m-menu__item--submenu m-menu__item--rel">
              <small>{{ 'header.shop' | transloco }}</small>
              <h4 class="m-menu__link-text" (click)="changeStore()">
                {{ currentStore.title | uiLabel }}
              </h4>
            </li>
          </ul>
        </div>

        <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
          <hx-language-select></hx-language-select>
          <div class="m-stack__item m-topbar__nav-wrapper">
            <ul class="m-topbar__nav m-nav m-nav--inline">
                <hc-notifier class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width" [ngClass]="{'m-dropdown--open': showDropdown }" (update)="showDropdown = $event"></hc-notifier>

              <li
                class="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                [ngClass]="{ 'm-dropdown--open': submenu }">
                <a (click)="toggleSubmenu()" class="m-nav__link m-dropdown__toggle">
                    <span class="m-topbar__username">
                      {{ 'header.hello' | transloco }}, {{ fullname }}!
                    </span>

                  <span class="m-topbar__userpic">
                    <img src="./assets/images/operator.jpg" class="m--img-rounded m--marginless m--img-centered" alt=""/>
                    <span class="m--online" [ngClass]="{'m--online-disable': !isOnline}" [title]="'header.titleStatus' | transloco"></span>
                  </span>
                </a>
                <div class="m-dropdown__wrapper">
                  <span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                  <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center" style="background: url(./assets/images/user_profile_bg.jpg); background-size: cover;">
                      <div class="m-card-user m-card-user--skin-dark">
                        <div class="m-card-user__pic">
                          <img src="./assets/images/operator.jpg" class="m--img-rounded m--marginless" alt=""/>
                        </div>
                        <div class="m-card-user__details">
                          <span class="m-card-user__name m--font-weight-500" (click)="navigateUrl('profile')">
                            {{ fullname }}
                          </span>
                          <span *ngIf="position" class="m-card-user__email m--font-weight-300 m-link">
                            {{ position }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="m-dropdown__body">
                      <div class="m-dropdown__content">
                        <ul class="m-nav m-nav--skin-light">
                          <li class="m-nav__item">
                            <a class="m-nav__link" (click)="changeStore()">
                              <span class="m-nav__link-text">
                                {{ 'header.changeStore' | transloco }}
                              </span>
                            </a>
                          </li>

                          <li class="m-nav__item">
                            <a class="m-nav__link" (click)="navigateUrl('profile')">
                              <span class="m-nav__link-text">
                                {{ 'header.profile' | transloco }}
                              </span>
                            </a>
                          </li>
                          <li class="m-nav__separator m-nav__separator--fit"></li>
                          <li class="m-nav__item">
                            <a class="btn btn-danger" (click)="logout()">
                              {{ 'header.out' | transloco }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- <li  class="m-nav__item">
                <a class="btn btn-danger m--margin-top-20" (click)="logout()">
                  {{ 'header.out' | transloco }}
                </a>
              </li> -->
            </ul>
          </div>
        </div>
        <!-- END: Topbar -->
      </div>
    </div>
  </div>
</header>

<ng-template #templateRef let-modal>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'header.chooseStore' | transloco }}</h3>
    <input required type="text" [(ngModel)]="query" (ngModelChange)="search()" name="search" class="form-control m-input">
  </div>

  <div class="modal-body">
    <div *ngFor="let item of copyStores">
      <button type="button" [disabled]="currentStore?.id === item.id" class="btn btn-primary btn-block m--margin-bottom-15 btn-wrap"
        (click)="setStore(item.id)">
        {{ item.city.title | uiLabel }} : {{ item.title | uiLabel }}
      </button>
    </div>
  </div>
</ng-template>
