import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { differenceInSeconds, intervalToDuration, parseISO } from 'date-fns';

@Component({
  selector: 'app-since',
  template: `{{value}}`
})
export class SinceComponent implements OnChanges, OnDestroy {

  @Input() date?: string;

  value?: string;

  private interval?: NodeJS.Timeout;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.date) {
      const parsed: Date = parseISO(this.date);
      this.value = this.fmt(differenceInSeconds(new Date(), parsed));
      this.interval = setInterval(() => {
        this.value = this.fmt(differenceInSeconds(new Date(), parsed));
      }, 1000);
    }
  }

  private fmt(seconds: number) {
    const duration = intervalToDuration({start: 0, end: seconds * 1000});

    let arr = [
      duration.hours,
      duration.minutes,
      duration.seconds,
    ];
    if ((duration.days ?? 0) > 0) {
      arr = [
        duration.days,
        duration.hours,
        duration.minutes,
        duration.seconds,
      ];
    }

    return arr
      .filter(Boolean)
      .map(val => (val + '').padStart(2, '0'))
      .join(':');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

}
