import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import {
  DataTable,
  DateRange,
  FeedbackStoreRankingModel,
  FeedbackTypeRankingModel,
  MoneyFlowInfoModel,
  MoneyFlowModel,
  PagedList,
  ProductReleaseReportModel,
  ReportProductModel,
  StorageFlowModel,
  SupplierPaymentModel,
  UserReceivedOrderModel
} from '../interface';
import { toHttpParams } from '../utils/globals';
import {
  ActionReportModel,
  PagedDeliveryOrderResponse,
  PagedStatisticsClientSalesModel,
  PagedStatisticsEmployeeSalesModel, StatisticsProductsModel,
  PaymentType,
  ProductInfoShortModel,
  ReportResourceService,
  RevenueModel,
  SortType,
  StatisticsCategoryPopularProductsModel,
  StatisticsCategoryProductSalesModel,
  StatisticsCategoryProductsTimeModel,
  StatisticsCitySalesModel,
  StatisticsClientSalesModel,
  StatisticsOrdersDayOfWeekModel,
  StatisticsOrdersTimeModel,
  StatisticsPaymentTypesModel,
  StatisticsPopularProductsModel,
  StatisticsProductSalesModel,
  StatisticsProductSourceTypesModel,
  StatisticsSalesModel,
  StatisticsSourceTypesModel,
  StatisticsStoreSalesModel,
  StoreBasicModel,
  StoreProductInfoModel,
  TotalSpendingModel,
  UnitOfMeasureModel,
  UserBasicModel, StatisticsAbcProductsModel
} from '../vn-api';
import { TimeInterval } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class HxReportService {

  constructor(
    private http: HttpClient,
    private reportResourceService: ReportResourceService
  ) {
  }

  getIncomeOutcome(params?: {
    storeId?: number,
    date?: string,
    categoryIds?: number[],
  }): Promise<IncomeOutcomeModel> {
    return firstValueFrom(this.http.get<IncomeOutcomeModel>(`/api/vanilla/reports/income-outcome`, {params: toHttpParams(params, true)}));
  }

  getDebts(params: { date?: DateRange, debtor?: boolean, storeIds?: number[] }): Observable<any> {
    return this.http.get(`/api/vanilla/reports/debts`, {params: toHttpParams(params, true)});
  }

  getRealizedProductGenerate(params: {
    storeId?: number,
    page?: number,
    limit?: number,
    date?: DateRange,
  }): Observable<any> {
    return this.http.get(`/api/vanilla/reports/realized-products/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  getRealizedProduct(params: {
    storeId?: number,
    page?: number,
    limit?: number,
    date?: DateRange,
  }): Observable<any> {
    return this.http.get(`/api/vanilla/reports/realized-products`, {params: toHttpParams(params, true)});
  }

  getStorageReport(params?: {
    storeId?: number,
    date?: DateRange,
    productInfoIds?: number[],
    categoryIds?: number[],
  }): Observable<any> {
    return this.http.get(`/api/vanilla/reports/storage-statistics`, {params: toHttpParams(params, true)});
  }

  productRelease(params: ReportParams): Observable<PagedList<ProductReleaseReportModel>> {
    return this.http.get<PagedList<ProductReleaseReportModel>>(`/api/vanilla/reports/product-release`, {params: toHttpParams(params, true)});
  }

  generateProductRelease(params: ReportParams): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(`/api/vanilla/reports/product-release/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  productShipmentReceived(params: ReportParams): Observable<DataTable<ReportProductModel>> {
    return this.http.get<DataTable<ReportProductModel>>(`/api/vanilla/reports/product-shipment-received`, {params: toHttpParams(params, true)});
  }

  generateProductShipmentReceived(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-shipment-received/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  productShipmentRefunded(params: ReportParams): Observable<DataTable<ReportProductModel>> {
    return this.http.get<DataTable<ReportProductModel>>(`/api/vanilla/reports/product-shipment-refunded`, {params: toHttpParams(params, true)});
  }

  generateProductShipmentRefunded(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-shipment-refunded/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  generateProductShipment(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-shipment/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  productSale(params: ReportParams): Observable<DataTable<ReportProductModel>> {
    return this.http.get<DataTable<ReportProductModel>>(`/api/vanilla/reports/product-sale`, {params: toHttpParams(params, true)});
  }

  generateProductSale(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-sale/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  productRefund(params: ReportParams): Observable<DataTable<ReportProductModel>> {
    return this.http.get<DataTable<ReportProductModel>>(`/api/vanilla/reports/product-refund`, {params: toHttpParams(params, true)});
  }

  generateProductRefund(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-refund/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  productRevenue(params: ReportParams): Observable<DataTable<ReportProductModel>> {
    return this.http.get<DataTable<ReportProductModel>>(`/api/vanilla/reports/product-revenue`, {params: toHttpParams(params, true)});
  }

  generateProductRevenue(params: ReportParams): Observable<any> {
    return this.http.get(`/api/vanilla/reports/product-revenue/generate`, {params: toHttpParams(params, true), responseType: 'arraybuffer', observe: 'response'});
  }

  closeDebt(body: DebtParams): Observable<any> {
    return this.http.post(`/api/vanilla/orders/close-debt`, body);
  }

  getFeedbackStoreRating(params: { date?: DateRange, storeIds?: number[] }): Observable<FeedbackStoreRankingModel[]> {
    return this.http.get<FeedbackStoreRankingModel[]>(`/api/vanilla/feedbacks/store/rating`, {params: toHttpParams(params, true)});
  }

  getFeedbackTypeRating(params: { date?: DateRange, storeIds?: number[] }): Observable<FeedbackTypeRankingModel[]> {
    return this.http.get<FeedbackTypeRankingModel[]>(`/api/vanilla/feedbacks/store/type/rating`, {params: toHttpParams(params, true)});
  }

  getUserReceivedOrder(params: ReportParams): Observable<UserReceivedOrderModel[]> {
    return this.http.get<UserReceivedOrderModel[]>(`/api/vanilla/reports/users/order-received`, {params: toHttpParams(params, true)});
  }

  getMoneyFlowInfos(params: MoneyFlowParams): Observable<MoneyFlowInfoModel[]> {
    return this.http.get<MoneyFlowInfoModel[]>(`/api/vanilla/reports/money-flow/full`, {params: toHttpParams(params, true)});
  }

  getMoneyFlows(params: MoneyFlowParams): Observable<MoneyFlowModel[]> {
    return this.http.get<MoneyFlowModel[]>(`/api/vanilla/reports/money-flow/by-store`, {params: toHttpParams(params, true)});
  }

  storageFlowReport(params: ReportParams): Observable<PagedList<StorageFlowModel>> {
    return this.http.get<PagedList<StorageFlowModel>>(`/api/vanilla/reports/storage-flow`, {params: toHttpParams(params, true)});
  }

  supplierPaymentReport(params: {
    page?: number,
    limit?: number,
    date?: DateRange,
    storeId?: number,
    supplierId?: number,
    paid?: boolean,
    filterByCreateDate?: boolean,
    timeInterval?: TimeInterval,
  }): Observable<PagedList<SupplierPaymentModel>> {
    return this.http.get<PagedList<SupplierPaymentModel>>(`/api/vanilla/reports/supplier-payments`, {params: toHttpParams(params, true)});
  }

  //Refact services
  getOrderReport(params?: GetOrdersParams): Promise<PagedDeliveryOrderResponse> {
    return firstValueFrom(this.reportResourceService.getReportOrders(params?.debt, params?.discount, params?.employee, params?.date?.from, params?.limit, params?.orderBy,
      params?.page, params?.paymentType, params?.sale, params?.sort, params?.storeIds, params?.date?.to));
  }

  getRevenue(params: {date?: DateRange, storeIds?: number[], saleUserId?: number}): Promise<RevenueModel[]> {
    return firstValueFrom(this.reportResourceService.getRevenueReport(params.date?.from, params.saleUserId, params.storeIds, params.date?.to));
  }

  getAction(params?: {date?: string, storeId?: number}): Promise<ActionReportModel[]> {
    return firstValueFrom(this.reportResourceService.getActionReport(params?.date, params?.storeId));
  }

  getStoreProductInfo(params?: { storeId?: number }): Promise<StoreProductInfoModel[]> {
    return firstValueFrom(this.reportResourceService.getStoreProductInfoReport(params?.storeId));
  }

  getTotalSpendingsByCategoryAndStores(params?: { fromDate?: string, storeIds?: number[], toDate?: string }): Promise<TotalSpendingModel[]> {
    return firstValueFrom(this.reportResourceService.getTotalSpendingsByCategoryAndStores(params?.fromDate, params?.storeIds, params?.toDate));
  }

  getTotalSpendingsByCategory(params?: { fromDate?: string, storeIds?: number[], toDate?: string }): Promise<TotalSpendingModel[]> {
    return firstValueFrom(this.reportResourceService.getTotalSpendingsByCategory(params?.fromDate, params?.storeIds, params?.toDate));
  }

  getStatisticsSales(params: SalesParams): Promise<StatisticsSalesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsSales(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsPaymentTypes(params: SalesParams): Promise<StatisticsPaymentTypesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsPaymentTypes(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsSourceTypes(params: SalesParams): Promise<StatisticsSourceTypesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsSourceTypes(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsOrdersTime(params: SalesParams): Promise<StatisticsOrdersTimeModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsOrdersTime(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsOrdersDayOfWeek(params: SalesParams): Promise<StatisticsOrdersDayOfWeekModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsOrdersDayOfWeek(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsPopularProducts(params: SalesParams): Promise<StatisticsPopularProductsModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsPopularProducts(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsCitySales(params: SalesParams): Promise<StatisticsCitySalesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsCitySales(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsStoreSales(params: SalesParams): Promise<StatisticsStoreSalesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsStoreSales(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsClientSales(params: SalesParams): Promise<PagedStatisticsClientSalesModel> {
    return firstValueFrom(this.reportResourceService.getStatisticsClientSales(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsEmployeeSales(params: SalesParams): Promise<PagedStatisticsEmployeeSalesModel> {
    return firstValueFrom(this.reportResourceService.getStatisticsEmployeeSales(params.cityId, params.clientId, params.fromDate, params.limit, params.page, params.paymentUserId, params.query, params?.storeIds, params.toDate));
  }

  getStatisticsCategoryProductSales(params: { fromDate?: string, storeIds?: number[], toDate?: string }): Promise<StatisticsCategoryProductSalesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsCategoryProductSales(params.fromDate, params?.storeIds, params.toDate));
  }

  getStatisticsProductSales(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, productInfoId?: number }): Promise<StatisticsProductSalesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsProductSales(params.categoryId, params.fromDate, params.productInfoId, params?.storeIds, params.toDate));
  }

  getStatisticsProductSourceTypes(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, productInfoId?: number }): Promise<StatisticsProductSourceTypesModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsProductSourceTypes(params.categoryId, params.fromDate, params.productInfoId, params?.storeIds, params.toDate));
  }

  getStatisticsCategoryPopularProducts(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, productInfoId?: number }): Promise<StatisticsCategoryPopularProductsModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsCategoryPopularProducts(params.categoryId, params.fromDate, params.productInfoId, params?.storeIds, params.toDate));
  }

  getStatisticsCategoryProductsTime(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, productInfoId?: number }): Promise<StatisticsCategoryProductsTimeModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsCategoryProductsTime(params.categoryId, params.fromDate, params.productInfoId, params?.storeIds, params.toDate));
  }

  getStatisticsCategoryProductsDayOfWeek(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, productInfoId?: number }): Promise<StatisticsCategoryProductsTimeModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsCategoryProductsDayOfWeek(params.categoryId, params.fromDate, params.productInfoId, params?.storeIds, params.toDate));
  }

  getStatisticsProducts(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, userId?: number }): Promise<StatisticsProductsModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsProducts(params.categoryId, params.fromDate, params?.storeIds, params.toDate, params.userId));
  }

  getStatisticsAbcProductsReport(params: { fromDate?: string, storeIds?: number[], toDate?: string, categoryId?: number, userId?: number }): Promise<StatisticsAbcProductsModel[]> {
    return firstValueFrom(this.reportResourceService.getStatisticsAbcProductsReport(params.categoryId, params.fromDate, params?.storeIds, params.toDate));
  }
}

export interface GetOrdersParams {
  sale?: number;
  employee?: number;
  date?: DateRange;
  limit?: number;
  page?: number;
  orderBy?: string;
  sort?: SortType;
  paymentType?: PaymentType;
  debt?: boolean;
  discount?: boolean;
  storeIds: number[];
}

interface ReportParams {
  storeId?: number;
  page?: number;
  limit?: number;
  productInfoIds?: number[];
  paymentTypes?: PaymentType[];
  categoryIds?: number[];
  date?: DateRange;
}

interface DebtParams {
  dateFrom?: string;
  dateTo?: string;
  storeIds?: number[];
  userIds?: number[];
}

interface MoneyFlowParams {
  date?: DateRange;
  storeIds?: number[];
}

interface SalesParams {
  page?: number,
  limit?: number,
  fromDate: string,
  toDate: string,
  storeIds?: number[],
  query?: string,
  cityId?: number,
  clientId?: number,
  paymentUserId?: number,
}

export interface IncomeOutcomeHeaderModel {
  stores: StoreBasicModel[];
  employees: UserBasicModel[];
}

export interface IncomeOutcomeBodyModel {
  dayBeforeRemainder: number;
  selectedDayRemainder: number;
  productInfo: ProductInfoShortModel;
  unitOfMeasure: UnitOfMeasureModel;
  stores: IncomeOutcomeStoreModel[];
  employees: IncomeOutcomeEmployeeModel[];
}

export interface IncomeOutcomeStoreModel {
  storeId: number;
  dayBeforeRemainder: number;
  income: number;
  selectedDayRemainder: number;
  discardedAmount: number;
  refundedAmount: number;
  exportAmount: number;
  confectionaryAmount: number;
  inventoryAmount: number;
}

export interface IncomeOutcomeEmployeeModel {
  userId: number;
  deliveryAmount: number;
}

export interface IncomeOutcomeModel {
  header: IncomeOutcomeHeaderModel;
  list: IncomeOutcomeBodyModel[];
}


