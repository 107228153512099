<label>
  {{ 'invoice-modal.cause' | transloco }}:
</label>

<div class="btn-group-sm">
  <button *ngFor="let item of cancelReasonList" (click)="setCancelReason(item)"
          class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': item !== cancelReason, 'btn-info': item === cancelReason}">
    {{ item }}
  </button>
  <button (click)="setCancelReason('otherReason')" class="btn m-btn--pill btn-sm" [ngClass]="{'btn-outline-info': 'otherReason' !== cancelReason, 'btn-info': 'otherReason' === cancelReason}">
    {{ 'buttons.other' | transloco }}
  </button>
</div>

<div class="m--margin-top-15" *ngIf="cancelReason === 'otherReason'">
  <textarea class="form-control m-input" [placeholder]="'cancel-reason.reasonNo' | transloco" [(ngModel)]="cancelReasonText" (ngModelChange)="updateData()"></textarea>
</div>
