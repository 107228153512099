<div class="row m--margin-top-10">
  <div class="col-md-12">
    <button type="button" *ngIf="!isCodeSend && !isConfirmOtp" class="btn btn-primary btn-sm"
      (click)="sendCode()">{{ 'buttons.sendCode' | transloco }}</button>

    <span *ngIf="isCodeSend && !isConfirmOtp">
      <form (submit)="checkCode()" class="d-inline-block">
        <input type="text" class="form-control d-inline-block" style="width: 90px;" [(ngModel)]="code" maxlength="4"
          name="code" placeholder="XXXX">

        <button type="submit" class="btn btn-sm">{{ 'buttons.confirmKod' | transloco }}</button>
      </form>

      <span class="m--margin-left-10" style="font-size: 14px;">
        <a (click)="sendCode()">{{ 'buttons.sendAgain' | transloco }}</a>
        <span *ngIf="resendTimeout && resendTimeout > 0"> {{ 'hc-otp.can' | transloco }} {{ resendTimeout }} {{ 'hc-otp.sec' | transloco }}</span>
      </span>
    </span>

    <span class="m--font-success" *ngIf="isConfirmOtp">
      <i class="la la-check"></i> {{ 'buttons.confirmed' | transloco }}
    </span>
  </div>
</div>
