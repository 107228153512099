import { Injectable } from '@angular/core';
import {
  AppEventResponse,
  CashboxResourceService,
  CreateCashboxOrderRequest,
  CurbsidePickupModel,
  FiscalPaperReadyResponse,
  KindType,
  OrderAction,
  PaidOrderRequest,
  RefundProductRequest,
  SaleProductModel,
  SaveOrderProductsRequest,
  SoldOrderRequest
} from 'hx-services';
import { firstValueFrom, ReplaySubject, Subject } from 'rxjs';
import { HC_KKT_PRINT } from '@cashbox-app/utils/util';

@Injectable({
  providedIn: 'root'
})
export class CashboxService {

  private curbsidePickupSubject = new Subject<boolean>();
  private fiscalPaperReadySubject = new ReplaySubject<boolean>(1);

  public readonly curbsidePickupObs = this.curbsidePickupSubject.asObservable();
  readonly fiscalPaperReadyObs = this.fiscalPaperReadySubject.asObservable();

  static isKktPrint() {
    return localStorage.getItem(HC_KKT_PRINT) === 'true';
  }

  constructor(
    private cashboxResource: CashboxResourceService,
  ) {
  }

  soldOrder(orderId: number, soldRequest: SoldOrderRequest) {
    return firstValueFrom(this.cashboxResource.cbSold(orderId, soldRequest));
  }

  saveProducts(orderId: number, request: SaveOrderProductsRequest) {
    return firstValueFrom(this.cashboxResource.cbSaveOrderProducts(orderId, request));
  }

  getSaleProducts(params: {
    storeId: number,
    kinds?: KindType[]
  }): Promise<SaleProductModel[]> {
    return firstValueFrom(this.cashboxResource.cbGetSaleProducts(params.storeId, params.kinds));
  }

  getDeliveryOrderList(params?: {
    limit?: number,
    page?: number,
    courierUserId?: number,
    actions?: OrderAction[],
    date?: string,
    query?: string
  }) {
    return firstValueFrom(this.cashboxResource.cbGetDeliveryOrders(
      params?.actions,
      params?.courierUserId,
      params?.date,
      params?.limit,
      params?.page,
      params?.query,
    ));
  }

  addCartProduct(orderId: number, params: { priceId: number }) {
    return firstValueFrom(this.cashboxResource.cbAddCartProduct(orderId, params.priceId));
  }

  /**
   * Создание заказа - заказ еще не оплачен на этой стадии
   */
  // createOrder(request: CreateOrderRequest): Observable<CreateOrderResponse> {
  //   return this.httpClient.post<CreateOrderResponse>(`/api/vanilla/cashbox/orders`, request);
  // }

  updateOrder(orderId: number, request: CreateCashboxOrderRequest) {
    return firstValueFrom(this.cashboxResource.cbSaveOrder(orderId, request));
  }

  soldPaidOrder(orderId: number, request: PaidOrderRequest) {
    return firstValueFrom(this.cashboxResource.cbSoldAndPaid(orderId, request));
  }

  paidOrder(orderId: number, request: PaidOrderRequest) {
    return firstValueFrom(this.cashboxResource.cbPaid(orderId, request));
  }

  generateCart() {
    return firstValueFrom(this.cashboxResource.cbGenerateCart());
  }

  cancelOrder(orderId: number, reason: string) {
    return firstValueFrom(this.cashboxResource.cbCancel(orderId, {reason: reason}));
  }

  productsCancel(orderId: number, request: RefundProductRequest) {
    request.total = request.total ?? 0;
    request.subTotal = request.subTotal ?? 0;
    return firstValueFrom(this.cashboxResource.cbRefundOrderProducts(orderId, request));
  }

  getNotFiscalizedOrders() {
    return firstValueFrom(this.cashboxResource.cbGetNonFiscalizedOrders());
  }

  saveCloseShiftEvent(result: any): Promise<void> {
    return firstValueFrom(this.cashboxResource.cbSaveShiftCloseEvent(result));
  }

  packOrders(ids: number[]): Promise<void> {
    return firstValueFrom(this.cashboxResource.cbPackOrders({ids: ids}));
  }

  saveCashboxState(state: { online: boolean }) {
    return firstValueFrom(this.cashboxResource.cbSaveCashboxState(state));
  }

  handleCurbsidePickupEvent(play: boolean) {
    this.curbsidePickupSubject.next(play);
  }

  getCurbsidePickups(params: { active: boolean }): Promise<CurbsidePickupModel[]> {
    return firstValueFrom(this.cashboxResource.cbGetCurbsidePickups(params.active));
  }

  markCurbsidePickupAsSeen(id: number) {
    return firstValueFrom(this.cashboxResource.cbMarkAsCashierSeen(id));
  }

  getFiscalPaperState(): Promise<FiscalPaperReadyResponse> {
    return firstValueFrom(this.cashboxResource.getFiscalPaperState());
  }

  setFiscalPaperReady(): Promise<AppEventResponse> {
    return firstValueFrom(this.cashboxResource.setFiscalPaperReady());
  }

  setFiscalPaperState(ready: boolean): Promise<FiscalPaperReadyResponse> {
    return firstValueFrom(this.cashboxResource.setFiscalPaperState(ready));
  }

  handleFiscalPaperReadyEvent(ready: boolean) {
    this.fiscalPaperReadySubject.next(ready);
  }
}
