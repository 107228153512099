<a (click)="showDropdown()" class="m-nav__link m-dropdown__toggle hc-notifier__toggle">
  <span class="hc-notifier__icon m-nav__link-icon">
    <i class="la la-envelope"></i>

    <span class="hc-notifier__counter" *ngIf="messageCount && messageCount.unseen > 0">
      {{ messageCount.unseen }}
    </span>
  </span>
</a>

<div class="m-dropdown__wrapper">
  <div class="m-dropdown__inner">
    <div class="hc-notifier__toolbar">
      <div class="hc-notifier__title">
        {{ 'hc-notifier.notifications' | transloco }}
      </div>

      <a (click)="markAllAsRead()" *ngIf="events.length">
        {{ 'hc-notifier.eventText' | transloco }}
      </a>
    </div>

    <ul class="hc-notifier__list">
      <ng-container *ngFor="let item of events">
        <li class="hc-notifier__item" [ngClass]="{'is--not_seen': !item.seenDate, 'is--not_read': !item.readDate}">
          <button [title]="'hc-notifier.titleRead' | transloco" type="button" (click)="markAsRead([item.id])" class="hc-notifier__mark-button" [ngClass]="{'invisible': item.readDate}"></button>

          <a (click)="readNotification(item)" class="hc-notifier__item-title">
            {{ item.topic }}
          </a>

          <span class="hc-notifier__time" style="white-space: nowrap;">
            {{ item.createDate | date: 'HH:mm / dd.MM' }}
          </span>
        </li>
      </ng-container>
    </ul>

    <div class="hc-notifier__notfound" *ngIf="!events.length">
      {{ 'hc-notifier.noNotifications' | transloco }}
    </div>

    <a target="_blank" [href]="profileUrl" class="hc-notifier__more">
      {{ 'hc-notifier.view' | transloco }}
    </a>
  </div>
</div>

<ng-template #templateRef let-modal>
  <div class="modal-header" *ngIf="selectNotification">
    <h3 class="modal-title">{{ selectNotification.topic }}</h3>
  </div>

  <div class="modal-body" *ngIf="selectNotification">
    <p>{{ selectNotification.text }}</p>

    <div class="text-right">
      <button type="button" class="btn btn-success" (click)="modal.dismiss()">
        {{ 'buttons.ok' | transloco }}
      </button>
    </div>
  </div>
</ng-template>
