import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

/**
 * Компонент иконки можно использовать как директиву
 * спрайт свг иконок генерируется через https://icomoon.io/app (проект для импорта иконок в happycake-svg-sprite.json)
 * дефолтные размер иконок 16px
 *
 *  example:
 *  `<hc-icon icon="nameOfIcon"></hc-icon>`
 *  `<hc-icon [icon]="nameOfIcon"></hc-icon>` динамическое название
 * nameOfIcon - название иконки (смотри hc-icon.component.css)
 * @export
 * @class HcIconComponent
 */
@Component({
  selector: 'hc-icon',
  exportAs: 'hcIcon',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./hc-icon.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HcIconComponent {
  @Input() enable = true;
  // name of icon == class of icon
  @Input()
  set icon(value: string) {
    if (value) {
      this.setClass(value);
    }
  }
  // icon class by @Input() icon
  @HostBinding('class') classNames!: string;

  constructor() { }

  private setClass(value: string) {
    if (typeof this.enable !== 'boolean') {
      this.enable = this.enable === 'true';
    }

    const isDisable = this.enable ? '' : 'is--disable';
    this.classNames = `hc-icon hc-icon--${value.toLowerCase()} ${isDisable}`;
  }

}
